import React from "react";
var classNames = require("classnames");

const paging = (props) => {
  const TotalPageNumberToView = 2;

  let hasPreDots = false;
  let hasPostDots = false;
  let btnArray = null;
  let numberOfPages = 0;

  const performPagination = () => {
    numberOfPages = Math.ceil(props.total / props.perPage);

    hasPreDots =
      props.currentPage > Math.floor(TotalPageNumberToView / 2) + 1 &&
      numberOfPages > TotalPageNumberToView;

    hasPostDots =
      numberOfPages - props.currentPage >
        Math.floor(TotalPageNumberToView / 2) &&
      numberOfPages > TotalPageNumberToView;

    let startPage = 0;
    if (props.currentPage > Math.floor(TotalPageNumberToView / 2) + 1) {
      if (
        numberOfPages - props.currentPage >
        Math.floor(TotalPageNumberToView / 2)
      ) {
        startPage = props.currentPage - Math.floor(TotalPageNumberToView / 2);
      } else {
        startPage = numberOfPages - TotalPageNumberToView + 1;
      }
    } else {
      startPage = 1;
    }

    // console.log("hasPreDots", hasPreDots);
    // console.log("props.total", props.total);
    // console.log("props.perPage", props.perPage);
    // console.log("hasPostDots", hasPostDots);
    // console.log("startPage", startPage);
    // console.log("props.currentPage", props.currentPage);

    btnArray = Array(
      numberOfPages > TotalPageNumberToView
        ? TotalPageNumberToView
        : numberOfPages
    )
      .fill()
      .map((item, index) => {
        return startPage + index;
      });
  };

  const pages = () => {
    return btnArray.map((item) => {
      return (
        <button
          type="button"
          className={classNames({
            btn: true,
            "btn-sm": true,
            "btn-outline-secondary": props.currentPage !== item,
            "btn-secondary": props.currentPage === item,
          })}
          key={item}
          onClick={(event) => props.onChange(event, item)}
        >
          {item}
        </button>
      );
    });
  };

  const getPrePostBtns = (direction) => {
    let disabled =
      ((direction === "pre" || direction === "first") &&
        props.currentPage === 1) ||
      ((direction === "post" || direction === "last") &&
        props.currentPage === numberOfPages);

    let pageToGo = 0;
    let text = "";

    switch (direction) {
      case "first":
        pageToGo = 1;
        text = "<<";
        break;
      case "pre":
        pageToGo = props.currentPage > 1 ? props.currentPage - 1 : 1;
        text = "<";
        break;
      case "post":
        pageToGo =
          props.currentPage < numberOfPages
            ? props.currentPage + 1
            : numberOfPages;
        text = ">";
        break;
      case "last":
        pageToGo = numberOfPages;
        text = ">>";
        break;
      default:
      // code block
    }

    return (
      <button
        type="button"
        className={classNames({
          btn: true,
          "btn-sm": true,
          "btn-outline-secondary": true,
        })}
        key={direction}
        disabled={disabled}
        onClick={(event) => props.onChange(event, pageToGo)}
      >
        {text}
      </button>
    );
  };

  return (
    <div className="btn-toolbar" role="toolbar">
      <div className="btn-group mr-2" role="group">
        {performPagination()}
        {getPrePostBtns("first")}
        {getPrePostBtns("pre")}
        {hasPreDots && <div>...</div>}
        {pages()}
        {hasPostDots && <div>...</div>}
        {getPrePostBtns("post")}
        {getPrePostBtns("last")}
      </div>
    </div>
  );
};

export default paging;
