import React, { Component } from "react";
import { connect } from "react-redux";
import Main from "./containers/Main/Main";
import Login from "./containers/Login/Login";
import MfaLogin from "./containers/Login/MfaLogin";
import * as actions from "./store/actions";
import "./App.css";
import ForgotPassword from "./containers/Login/ForgotPassword";
import ResetPassword from "./containers/Login/ResetPassword";
import queryString from "query-string";
import ChangePassswordForgot from "./containers/Login/ChangePassswordForgot";
import PrintPreview from "./components/PrintPreview";
import ErrorHandler from "./components/ErrorHandler";

class App extends Component {
  // constructor(props) {
  //   super(props);
  //  // this.state = { authenticated: false };
  // }

  componentDidMount() {
    const parsed = queryString.parse(window.location.search);
    if (parsed.token) {
      this.props.onCheckForgotPasswordResetToken(parsed.token);
    }
    this.props.onTryAutoSignup();
  }

  render() {
    return (
      <ErrorHandler>
        <div className="h-100">
          {this.props.isAuthenticated === true ? (
            this.props.match && this.props.match.params.id === 1 ? (
              <PrintPreview />
            ) : (
              <Main
                isAuthenticated={this.props.isAuthenticated}
                userInfo={this.props.userInfo}
              />
            )
          ) : this.props.mfaSessionKey ? (
            <MfaLogin />
          ) : this.props.forgotPassword ? (
            <ForgotPassword />
          ) : this.props.resetForgotPassword ? (
            <ResetPassword />
          ) : this.props.isExpired ? (
            <ChangePassswordForgot />
          ) : (
            <Login />
          )}
        </div>
      </ErrorHandler>
    );
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated:
      state.auth.token !== null && state.auth.token !== undefined,
    userInfo: state.auth.userInfo,
    mfaSessionKey: state.auth.mfaSessionKey,
    forgotPassword: state.auth.forgotPassword,
    resetForgotPassword: state.auth.resetForgotPassword,
    isExpired: state.auth.isExpired
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onCheckForgotPasswordResetToken: token =>
      dispatch(actions.checkForgotPasswordResetToken(token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
