import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const CelDropdown = (props) => {
  return (
    <td style={props.width && { width: props.width }}>
      <Select
        isDisabled={props.disabled !== undefined ? props.disabled : ""}
        id={props.Id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        options={props.options}
        {...props}
      />
    </td>
  );
};

export default CelDropdown;

CelDropdown.propTypes = {
  value: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    type: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      type: PropTypes.string,
    })
  ),
};
