import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import service from "../../services/userManagement.service";
import axiosInterceptors from "../../hoc/axiosInterceptors";
import Textbox from "../../components/Textbox";
import Checkbox from "../../components/Checkbox";
import Version from "../../components/Version";
import Button from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import Logo from "../../components/Logo";
import joi from "joi";
import Form from "../../components/Form";
import "./Login.css";
import { Spinner } from "../../components";
import queryString from "query-string";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  initialState = {
    message: "",
    isSubmit: false,
    controls: {
      confirmPassword: {
        value: "",
        valid: false,
        validMessage: "",
        touched: false,
        validation: joi.string().required()
      },
      password: {
        value: "",
        valid: false,
        validMessage: "",
        touched: false,
        validation: joi.string().required()
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.resetForm();
  }

  resetForm() {
    this.setState(this.initialState);
  }

  //   isCompare = true;
  onSubmitHandler = event => {
    event.preventDefault();
    const parsed = queryString.parse(window.location.search);
    if (parsed.token) {
      this.setState({ ...this.state, isSubmit: true });
      Object.keys(this.state.controls).map(controlName => {
        console.log(this.state.controls[controlName].valid);
        this.formIsValid =
          this.state.controls[controlName].valid && this.formIsValid;
      });

      //   if (this.formIsValid) {
      //     this.isCompare =
      //       this.state.controls.confirmPassword.value ==
      //       this.state.controls.password.value;
      //     if (!this.isCompare) {
      //       let controls = this.state.controls;
      //       Object.keys(controls).map(controlName => {
      //         controls[controlName].valid = false;
      //       });
      //       this.setState({ ...this.state, controls: controls });
      //     } else {
      //       let controls = this.state.controls;
      //       Object.keys(controls).map(controlName => {
      //         controls[controlName].valid = true;
      //       });
      //       this.setState({ ...this.state, controls: controls });
      //     }
      //   }

      let compare =
        this.state.controls.confirmPassword.value ==
        this.state.controls.password.value;
      if (!compare) {
        this.setState({
          ...this.state,
          message: "password and confirme password in not match"
        });
      }
      if (this.formIsValid === true && compare) {
        this.props.onResetForgotPassword(
          this.state.controls.confirmPassword.value,
          parsed.token
        );
      }
    }
  };

  checkValidity = (value, validation) => {
    let result = joi.validate(value, validation);
    return result;
  };

  formIsValid = false;
  onChangeHandler = (controlName, value) => {
    this.formIsValid = true;
    var validateResault = this.checkValidity(
      value,
      this.state.controls[controlName].validation
    );
    // this.formIsValid = validateResault.error ? false : true;
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: value,
        valid: validateResault.error ? false : true,
        validMessage: validateResault.error
          ? validateResault.error.details[0].message
          : "",
        touched: true
      }
    };
    this.setState({ ...this.state, controls: updatedControls });
    console.log(this.state);
  };

  backToLoginPageHandler = () => {
    this.props.onBackToLoginPage();
  };

  render() {
    console.log("login render");
    console.info(this.props.loginStatus);
    return (
      <div className="body text-center wrapper">
        <Form classNames="form-signin">
          {this.props.loading && <Spinner />}
          <Logo />
          <h1 className="h3 mb-3 font-weight-normal">Reset Password</h1>
          <div className="col-md-12 p-0 mt-3" />
          {this.props.resetPaswordMessage === "" ? (
            <div>
              <Textbox
                type="password"
                label=""
                placeholder="password"
                id="password"
                onChange={this.onChangeHandler}
                value={this.state.controls.password.value}
                valid={this.state.controls.password.valid}
                validMessage={this.state.controls.password.validMessage}
                isSubmited={this.state.isSubmit}
              />
              <Textbox
                type="password"
                label=""
                placeholder="Confirm Password"
                id="confirmPassword"
                onChange={this.onChangeHandler}
                value={this.state.controls.confirmPassword.value}
                valid={this.state.controls.confirmPassword.valid}
                validMessage={this.state.controls.confirmPassword.validMessage}
                isSubmited={this.state.isSubmit}
              />
              <Button
                // disable={this.formIsValid ? "" : disabled}
                className="btn btn-lg btn-primary btn-block "
                type="submit"
                label="Reset Password"
                onClick={event => this.onSubmitHandler(event)}
              />
            </div>
          ) : (
            <div>
              <ErrorMessage
                {...this.props}
                info={this.props.resetPaswordMessage}
                className="col-md-12"
              />
            </div>
          )}

          <div className="col-md-12 p-0 mt-4">
            <p>
              <a
                href="#"
                className="text-primary"
                onClick={this.backToLoginPageHandler}
              >
                Back to login page
              </a>
            </p>
          </div>
          <ErrorMessage
            {...this.props}
            error={this.state.message}
            className="col-md-12"
          />
          <Version />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    loginStatus: state.auth.loginStatus,
    isTokenValid: state.auth.isTokenValid,
    resetPaswordMessage: state.auth.resetPaswordMessage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onBackToLoginPage: () => dispatch(actions.backToLoginPage()),
    onResetForgotPassword: (newPassword, token) =>
      dispatch(actions.resetForgotPassword(newPassword, token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(axiosInterceptors(ResetPassword, service));
