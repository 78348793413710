import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import helper from "../../helpers/listReducer";
const Helper = helper();

const initialState = {
  ...Helper.initState(),
  sort_by: "Title"
};

const listSuccess = (state, action) => {
  return updateObject(state, {
    ...Helper.successState(action)
  });
};

const listFail = (state, action) => {
  return updateObject(state, {
    ...Helper.failState(action)
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_LOYALTY_RULE_SUCCESS:
      return listSuccess(state, action);
    case actionTypes.LIST_LOYALTY_RULE_FAIL:
      return listFail(state, action);
    case actionTypes.LIST_LOYALTY_RULE_START:
      return Helper.start(state, action);
    case actionTypes.LIST_LOYALTY_RULE_SELECT:
      return Helper.select(state, action);
    case actionTypes.LIST_LOYALTY_RULE_EXPORT_START:
      return Helper.exportStart(state, action);
    case actionTypes.LIST_LOYALTY_RULE_EXPORT_SUCCESS:
      return Helper.exportSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
