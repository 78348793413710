export const VIEW_USER_LIST = 1;
export const CREATE_USER = 2;
export const EDIT_USER = 3;
export const EXPORT_USERLIST = 22;
export const CHANGE_USER_STATUS = 21;
export const EDIT_USER_ACCESS = 5;
export const RESET_PASSWORD = 6;
export const VIEW_ROLE = 11;
export const CREATE_ROLE = 12;
export const EDIT_ROLE = 13;
export const VIEW_ORGANIZATION = 16;
export const CREATE_ORGANIZATION = 18;
export const EDIT_ORGANIZATION = 19;
export const VIEW_ACCESS_REPORT = 20;
export const VIEW_PARTNER_APPLICATION_INBOX = 31;
export const EXPORT_PARTNER_APPLICATION_INBOX = 32;
export const PARTNER_APPLICATION_UPLOAD = 33;
export const VIEW_VOUCHER = 40;
export const CREATE_VOUCHER = 41;
export const CREATE_TEMPLATE=72;
export const CREATE_NEW_VOUCHER = 41;
export const VOUCHER_LIST =41;
export const EDIT_VOUCHER = 42;
export const EXPORT_VOUCHER_LIST = 46;
export const CREATE_VOUCHERCODE = 43;
export const EXPORT_VOUCHERCODE_BATCHES_LIST = 44;
export const VOUCHER_CODE_VOID = 45;
export const ADMIN_PORTAL_LOGIN = 10;

export const VIEW_LOYALTY = 50;
export const CREATE_LOYALTY = 51;
export const EDIT_LOYALTY = 52;
export const EXPORT_LOYALTY_LIST = 53;

export const PRODUCT_ADD = 60;
export const CREATE_FARE_STTOST = 65;
export const UPDATE_FARE_STTOST = 66;
export const VIEW_FARE = 67;
export const CREATE_PRODUCT = 71;

export const TRAIN_SERVICE_STATUS = 84;

export const VOID_TICKET = 85;
