import React from "react";
import CelAction from "./CelAction";
import CelButton from "./CelButton";
import CelCheckbox from "./CelCheckbox";
import CelCheckboxSelect from "./CelCheckboxSelect";
import CellDropdown from "./CelDropdown";
import CelIcon from "./CelIcon";
import CelImage from "./CelImage";
import CelLabel from "./CelLabel";
import CelLink from "./CelLink";
import CelProcess from "./CelProcess";
import CellTextbox from "./CelTextbox";
import CelTrueIcon from "./CelTrueIcon";

const classNames = require("classnames");

const row = (props) => {
  const onSelectHandler = (event, eventType, id, action) => {
    if (eventType === "checkboxClick") {
      props.onCheckboxClick(id, props.colsData, action);
    } else if (eventType === "buttonClick") {
      event.preventDefault();
      event.stopPropagation();
      props.onButtonClick(id, action);
    } else if (eventType === "rowClick") {
      if (props.selectable) {
        props.onSelect(id, props.colsData);
      }
    } else {
      props.onSelect(id, props.colsData);
    }
  };

  const colsarr = props.cols.map(function (col, index) {
    let c;
    switch (col.type) {
      case "process":
        c = (
          <CelProcess
            permission={col.permission}
            status={props.colsData[col.field]}
            onSelectHandler={onSelectHandler}
            colsData={props.colsData}
            col={col}
            index={index}
            {...props}
          />
        );
        break;
      case "label":
        c = (
          <CelLabel
            key={index}
            format={col.format}
            value={props.colsData[col.field]}
            width={col.width}
          />
        );
        break;

      case "link":
        c = (
          <CelLink key={index} href={props.colsData[col.href]}>
            {props.colsData[col.field]}
          </CelLink>
        );
        break;
      case "checkbox":
        c = <CelCheckbox key={index} checked={props.colsData[col.field]} />;
        break;
      case "checkboxSelect":
        c = (
          <CelCheckboxSelect
            //checked={props.isSelected}
            field={col.field}
            checked={props.colsData[col.field]}
            disabled={props.colsData[col.disabledField]}
            change={onSelectHandler}
            key={index}
            id={props.colsData[props.keyField]}
          />
        );
        break;
      case "action":
        c = (
          <CelAction
            actionUrl={col.routUrl}
            id={props.colsData.key}
            name={col.title}
            key={index}
          />
        );
        break;
      case "icon":
        c = <CelIcon id={props.colsData.key} key={index} icon={col.icon} />;
        break;
      case "trueIcon":
        c = (
          <CelTrueIcon
            id={props.colsData.key}
            key={index}
            value={props.colsData[col.field]}
          />
        );
        break;
      case "button":
        c = (
          <CelButton
            permission={col.permission}
            action={col.action}
            id={props.colsData[props.keyField]}
            name={
              col.field && props.colsData[col.field]
                ? props.colsData[col.field]
                : col.title
            }
            key={index}
            className={col.className}
            onClick={onSelectHandler}
            disabled={
              col.disabledField !== undefined && col.disabledField !== null
                ? props.colsData[col.disabledField]
                : false
            }
          />
        );
        break;
      case "textbox":
        c = (
          <CellTextbox
            key={index}
            id={props.colsData[props.keyField]}
            format={col.format}
            value={props.colsData[col.field]}
            onChange={(id, value) => props.onChange(id, col.field, value)}
            prepend={col.prepend}
            append={col.append}
            width={col.width}
          />
        );
        break;
      case "dropdown":
        c = (
          <CellDropdown
            id={props.colsData[props.keyField]}
            key={index}
            disabled={col.disabled !== undefined ? props.disabled : ""}
            placeholder={col.placeholder}
            value={props.colsData[col.field]}
            rowData={props.colsData}
            filterFunc={col.filterFunc}
            onChange={(value) =>
              props.onChange(props.colsData[props.keyField], col.field, value)
            }
            options={
              col.filterFunc
                ? col.filterFunc(props.colsData, col.options)
                : col.options
            }
            width={col.width}
          />
        );
        break;
      case "image":
        c = (
          <CelImage
            id={props.colsData[props.keyField]}
            key={index}
            data={props.colsData[col.field]}
            filetype={col.filetype}
            style={col.style}
            width={col.width}
          />
        );

        break;
      default:
        break;
    }
    return c;
  }, this);

  const rowString = () => {
    var rowClass = classNames({
      "table-info": props.isSelected,
    });
    let c = (
      <tr
        onClick={(event) => onSelectHandler(event, "rowClick", props.id)}
        className={rowClass}
      >
        {colsarr}
      </tr>
    );
    return c;
  };

  return rowString();
};

export default row;
