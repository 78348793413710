import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  orgMngList: null,
  errorMng: null,
  orgList: null,
  error: null,
  loading: false,
  loadingMng: false,
  sort_by: "org_title",
  sort_dir: "asc",
  page_size: 10,
  searchKeyword: "",
  selectedOrg: null,
  currentPage: 1,
  total: 0,
  loadingMngExport: false,
  orgExportList: null
};

const listOrgMngSuccess = (state, action) => {
  console.log("ORG List Reducer");
  console.dir(action.orgList);
  return updateObject(state, {
    orgMngList: action.orgList,
    errorMng: null,
    loadingMng: false,
    total: action.total,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    page_size: action.filterOptions.page_size,
    searchKeyword: action.filterOptions.searchKeyword,
    currentPage: action.filterOptions.currentPage,
    orgExportList: null
  });
};

const listOrgMngFail = (state, action) => {
  return updateObject(state, {
    errorMng: action.error,
    loadingMng: false
  });
};

const listOrgMngStart = (state, action) => {
  return updateObject(state, {
    loadingMng: true
  });
};

const listOrgMngSelect = (state, action) => {
  return updateObject(state, {
    selectedOrg: action.selectedOrg //updateMultiSelect(state.selectedUsers, action.selectedUsers)
  });
};

const listOrgMngClearSelect = (state, action) => {
  return updateObject(state, {
    selectedOrg: null
  });
};

const addOrgMngStart = (state, action) => {
  return updateObject(state, {
    loadingMngAdd: true
  });
};

const addOrgMngSuccess = (state, action) => {
  console.log("ORG Add and Edit Reducer");
  return updateObject(state, {
    errorMng: null,
    loadingMngAdd: false,
    selectedOrg: null
  });
};

const orgMngExportStart = (state, action) => {
  return updateObject(state, {
    loadingMngExport: true
  });
};

const orgMngExportSuccess = (state, action) => {
  return updateObject(state, {
    loadingMngExport: false,
    orgExportList: action.orgExportList
  });
};

//-------------------------------------------------------

const listOrgSuccess = (state, action) => {
  const Total = Array.isArray(action.orgList) ? action.orgList.pop().Total : 0;

  let orgListOption = action.orgList.map(item => {
    return { value: item.org_id, label: item.org_title };
  });
  return updateObject(state, {
    orgList: orgListOption,
    error: null
  });
};

const listOrgFail = (state, action) => {
  return updateObject(state, {
    error: action.error
  });
};

const listOrgLoading = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

//--------------------------------------------------------

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_ORG_SUCCESS:
      return listOrgSuccess(state, action);
    case actionTypes.LIST_ORG_FAIL:
      return listOrgFail(state, action);
    case actionTypes.LIST_ORG_LOADING:
      return listOrgLoading(state, action);
    case actionTypes.LIST_ORG_MNG_SUCCESS:
      return listOrgMngSuccess(state, action);
    case actionTypes.LIST_ORG_MNG_FAIL:
      return listOrgMngFail(state, action);
    case actionTypes.LIST_ORG_MNG_START:
      return listOrgMngStart(state, action);
    case actionTypes.LIST_ORG_MNG_SELECT:
      return listOrgMngSelect(state, action);
    case actionTypes.LIST_ORG_MNG_CLEAR:
      return listOrgMngClearSelect(state, action);
    case actionTypes.ADD_ORG_MNG_START:
      return addOrgMngStart(state, action);
    case actionTypes.ADD_ORG_MNG_SUCCESS:
      return addOrgMngSuccess(state, action);
    case actionTypes.LIST_ORG_MNG_EXPORT_START:
      return orgMngExportStart(state, action);
    case actionTypes.LIST_ORG_MNG_EXPORT_SUCCESS:
      return orgMngExportSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
