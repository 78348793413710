import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Image from "../Image";

const celLabel = props => {
  return (
    <td style={props.width && { width: props.width }}>
      <Image data={props.data} fileType={props.filetype} style={props.style} />
    </td>
  );
};

export default celLabel;

celLabel.propTypes = {};
