import React from "react";
import { CSVLink } from "react-csv";
import formHelper from "../helpers/form.js";
import Spinner from "./Spinner/Spinner";

const Export = props => {
  const control = () => {
    let s = null;
    if (props.loading) {
      s = <Spinner />;
    } else if (formHelper().isData(props.data, 0)) {
      s = (
        <CSVLink
          data={props.data}
          headers={props.headers}
          filename={props.fileName}
        >
          download
        </CSVLink>
      );
    } else {
      s = <div onClick={() => props.onExport()}>{props.children}</div>;
    }
    return s;
  };
  return <>{control()}</>;
};
export default Export;
