import React from "react";

const TimeFormat = (props) => {
  return (
    <>
      {props.time ||
        (props.children &&
          new Intl.DateTimeFormat(props.format || "default", {
            ...props,
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(new Date(props.time || props.children)))}
    </>
  );
};

export default TimeFormat;
