import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  roleList: null,
  error: null,
  loading: false,
  sort_by: "r_title",
  sort_dir: "asc",
  searchKeyword: "",
  selectedRole: null,
  currentPage: 1,
  total: 0,
  deletedRole: null
};

const listRoleStart = (state, action) => {
  console.log("listRoleStart");
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const listRoleSuccess = (state, action) => {
  return updateObject(state, {
    roleList: action.list,
    loading: false,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    selectedRole: null,
    total: action.total,
    currentPage: action.filterOptions.currentPage,
    error: null
  });
};

const listRoleFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    error: action.error
  });
};

const searchboxChange = (state, action) => {
  console.log("listRole Search change Reducer");
  return updateObject(state, {
    searchKeyword: action.keyword
  });
};

const selectRole = (state, action) => {
  console.log("Select Role Reducer " + action.selectedRoles);
  return updateObject(state, {
    selectedRole: action.selectedRoles
  });
};

const deleteRoleStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const deleteRoleSuccess = (state, action) => {
  return updateObject(state, {
    deletedRole: action.info,
    loading: false,
    error: null
  });
};

const deleteRoleFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_ROLES_SUCCESS:
      return listRoleSuccess(state, action);
    case actionTypes.LIST_ROLES_FAIL:
      return listRoleFail(state, action);
    case actionTypes.LIST_ROLES_START:
      return listRoleStart(state, action);
    case actionTypes.LIST_ROLES_SEARCHBOX_CHANGE:
      return searchboxChange(state, action);
    case actionTypes.LIST_ROLES_SELECT:
      return selectRole(state, action);

    case actionTypes.DELETE_ROLES_SUCCESS:
      return deleteRoleSuccess(state, action);
    case actionTypes.DELETE_ROLES_FAIL:
      return deleteRoleFail(state, action);
    case actionTypes.DELETE_ROLES_START:
      return deleteRoleStart(state, action);

    default:
      return state;
  }
};

export default reducer;
