import service from "../../services/loyalty.servic";
import infoActions from "../../helpers/infoActions";
const addActions = infoActions("ADD_LOYALTY_RULE");
const editActions = infoActions("EDIT_LOYALTY_RULE");

export const addLoyaltyRule = params => {
  console.log("addLOYALTYRULE: " + JSON.stringify(params));
  return dispatch => {
    dispatch(addActions.start());
    service
      .post("/loyalty/rule", params)
      .then(res => {
        setTimeout(() => {
          dispatch(addActions.success(res.data));
        }, 2000);
      })
      .catch(err => {
        dispatch(addActions.fail(err.message));
      });
  };
};

export const updateLoyaltyRule = (voucherId, params) => {
  return dispatch => {
    dispatch(editActions.start());
    service
      .put(`/loyalty/rule/${voucherId}`, params)
      .then(res => {
        dispatch(editActions.success(res.data));
      })
      .catch(err => {
        dispatch(editActions.fail(err.message));
      });
  };
};
