import service from "../../services/partnerManagement.service";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_WORKFLOW_LOG");

export const listPartneWorkflowLogList = (filterOptions, isExport = false) => {
  const url = `/partner/application/${
    filterOptions.applicationId
  }/workflowLog?page_number=${filterOptions.currentPage}&page_size=${
    filterOptions.page_size
  }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}`;
  console.log(url);
  return dispatch => {
    dispatch(ListActions.start(isExport));
    service
      .get(url)
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
