import React, { Suspense, lazy, Component } from "react";
import { NavLink, Link } from "react-router-dom";
import _ from "lodash";
import classnames from "classnames";
import Navbar from "../../components/layout/Navbar/Navbar";
// import Sidebar from "../../components/layout/Sidebar/Sidebar";
import Sidebar from "../../components/Menu/Sidebar";
import Spinner from "../../components/Spinner/Spinner";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Version from "../../components/Version";
import NotFound from "../../components/layout/NotFound";
import Logout from "../Logout/Logout";
import { PermissionsContext } from "../../hoc/PermissionsContext";
import { currentMainMenu } from "../../helpers/utility";

import MainMenu from "../../MainMenu";
import SideMenu from "../../SideMenu";

import ErrorHandler from "../../components/ErrorHandler";
import "./main.css";
import { Button, Col, Icon, Row } from "../../components";

class Main extends Component {


  allowedSideMenu = [];
  allowedMainMenu = [];
  userPermissions = [];
  routes = [];
  componentDidMount() {
  // Set userRole and userName in session storage
  sessionStorage.setItem('userRole', this.props.userInfo.userType);
  sessionStorage.setItem('userName', this.props.userInfo.fullName);

  // Check if userRole or userName is null
  const userRole = sessionStorage.getItem('userRole');
  const userName = sessionStorage.getItem('userName');

  
}

  


  constructor(props) {
    super(props);
   
  
    

    this.userPermissions = this.props.userInfo.permissions
      ? this.props.userInfo.permissions.split(",")
      : null;

    this.allowedSideMenu =
      this.userPermissions &&
      SideMenu.filter((f) => {
        if (f.permission.includes(",")) {
          const permissions = f.permission.split(",");
          const matchingPermission = permissions.find((p) => this.userPermissions.includes(p));
          if (matchingPermission) {
            return matchingPermission;
          }
        } else {
          return this.userPermissions.includes(f.permission);
        }
      });

    this.allowedMainMenu = MainMenu.filter(
      (item) =>
        _.intersection(item.permissions, this.userPermissions).length > 0,
    );

    this.routes =
      this.userPermissions !== null
        ? this.generateRoutes(this.allowedSideMenu, this.allowedMainMenu)
        : [];

    const pathArray = window.location.pathname
      .split(/[/,?]+/)
      .filter((item) => item !== "");

    let mainPath = "home";
    let sidePath = "";

    if (pathArray !== null && Array.isArray(pathArray)) {
      if (pathArray.length > 0) {
        mainPath = pathArray[0];
      }
      if (pathArray.length > 1) {
        sidePath = pathArray[1];
      }
    }

    this.state = {
      currentMainMenu: mainPath,
      currentSideBar: sidePath,
      showSidebarMenu: true,
      shouldReload : true
    };
  }

  onMenuItemSelectHandler = (item) => {
  
    this.setState({ ...this.state, currentSideBar: item.toLowerCase() });
  };

  onMainMenuItemSelectHandler = (item) => {
   
    this.setState({ ...this.state, currentMainMenu: item.toLowerCase() });
  };

  getNavbar() {
   
    return (
      <Navbar
        onItemSelectHandler={this.onMainMenuItemSelectHandler}
        menuItems={MainMenu}
        userInfo={this.props.userInfo}
        persmissions={this.userPermissions}
      />
    );
  }

  sideBarMenues = () => {
    
    return this.allowedSideMenu.filter((item) => {
      return (
       
        item.menu && item.main.toLowerCase() === this.state.currentMainMenu
      );
    });
  };
  

  generateRoutes(sidebarPages, mainMenuPages) {
    let routes = [];
    let index = 0;

    mainMenuPages.forEach((page) => {
      routes.push(
        <Route
          exact
          path={page.path}
          component={page.component}
          key={++index}
        />,
      );
    });

    sidebarPages.forEach((page) => {
      routes.push(
        <Route
          exact
          path={page.path}
          component={page.component}
          key={++index}
        />,
      );
    });

    return routes;
  }

  onMenuClickHandler = () => {
    if (window.innerWidth <= 760) {
      this.setState({
        ...this.state,
        showSidebarMenu: !this.state.showSidebarMenu,
        
      });
    }
  };
  

  render() {
    
    return (
      <div id="main" className="wrapper">
        <PermissionsContext.Provider value={this.userPermissions}>
          {this.userPermissions === null && (
            <>
              {alert("User does not have the right roles or permissions")}
              <Logout />
            </>
          )}
          {this.userPermissions !== null && (
            <>
              <nav
                id="sidebar"
                className={classnames({ active: !this.state.showSidebarMenu })}
              >
                <div className="sidebar-header">
                  <h3 className="text-center">Admin Portal</h3>
                </div>

                <ul className="list-unstyled components">
                  <div id="userProfile" className="text-center">
                    <div id="profileImage">
                      {this.props.userInfo.fullName
                        .replace("MR ", "")
                        .replace("MS ", "")
                        .split(" ")
                        .reduce((newItem, currentItem) => {
                          return newItem + currentItem[0];
                        }, "")}
                    </div>
                    <div className="userName mt-1">
                      {this.props.userInfo
                        ? this.props.userInfo.fullName
                          .replace("MR ", "")
                          .replace("MS ", "")
                        : ""}
                    </div>

                    
                    <div>
                    {this.props.userInfo ? this.props.userInfo.userType : ""}
                    </div>


                    <div className="mt-3 text-center signout">
                      <a href="/logout">Sign out</a>
                    </div>
                  </div>

                  <Sidebar
                    userInfo={this.props.userInfo}
                    persmissions={this.userPermissions}
                    onMenuClick={this.onMenuClickHandler}
                  />
                </ul>
              </nav>

              <div id="content" style={{ width: "100%" }}>
                <div className="container-fluid">
                  <div className="d-block d-sm-block d-md-none">
                    <Row className="bg-dark ">
                      <Col className="col-6 col-sm-6 col-md-6">
                        <div
                          onClick={this.onMenuClickHandler}
                          className="btn-secondary m-2 p-2 text-center "
                          style={{
                            width: 40,
                            borderRadius: 5,
                            cursor: "pointer",
                          }}
                        >
                          <Icon
                            foreColor="white"
                            icon={this.state.showSidebarMenu ? "menu" : "close"}
                          ></Icon>
                        </div>
                      </Col>
                      <Col className="col-6 col-sm-6 col-md-6 text-white text-right  align-self-center">
                        Admin Portal
                      </Col>
                    </Row>
                  </div>

                  <Suspense
                    fallback={
                      <div>
                        <Spinner />
                      </div>
                    }
                  >
                    <Switch>
                      {this.routes}
                      <Redirect exact from="/users" to="/users/list" />
                      <Redirect exact from="/products" to="/products/index" />
                      <Route component={NotFound} />
                    </Switch>
                  </Suspense>
                  <Version />
                </div>
              </div>
            </>
          )}
        </PermissionsContext.Provider>
      </div>
    );
  }
}

export default Main;
