import service from "../../services/voucher.service";
import infoActions from "../../helpers/infoActions";
const addActions = infoActions("ADD_VOUCHER");
const editActions = infoActions("EDIT_VOUCHER");
const extendActions = infoActions("EXTEND_VOUCHER");

export const addVoucher = params => {
  return dispatch => {
    dispatch(addActions.start());
    service
      .post("/voucher", params)
      .then(res => {
        dispatch(addActions.success(res.data));
      })
      .catch(err => {
        dispatch(addActions.fail(err.message));
      });
  };
};

export const updateVoucher = (voucherId, params) => {
  return dispatch => {
    dispatch(editActions.start());
    service
      .put(`/Voucher/${voucherId}`, params)
      .then(res => {
        dispatch(editActions.success(res.data));
      })
      .catch(err => {
        dispatch(editActions.fail(err.message));
      });
  };
};

export const voucherExtendSubmit = (voucherId, params) => {
  console.log(params);
  return dispatch => {
    dispatch(extendActions.start());
    service
      .put(`/voucher/${voucherId}/extendvalidity`, params)
      .then(res => {
        dispatch(extendActions.success(res.data));
      })
      .catch(err => {
        dispatch(extendActions.fail(err.message));
      });
  };
};
