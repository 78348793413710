import service from "../../services/voucher.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
import infoActions from "../../helpers/infoActions";
const ListActions = listActions("LIST_VOUCHER_CODE");
const PrintActions = listActions("PRINT_VOUCHER_CODE");
const InfoActions = infoActions("VOID_VOUCHER_CODE");

export const selectVoucherCode = (row) => {
  return {
    type: actionTypes.LIST_VOUCHER_CODE_SELECT,
    selectedRow: row,
  };
};

export const voidVoucherCode = (ids) => {
  return (dispatch) => {
    dispatch(InfoActions.start());
    service
      .post("/voucher/voucherbatch/vouchercode/void", { VoucherCodesID: ids })
      .then((res) => {
        dispatch(InfoActions.success(res.data));
      })
      .catch((err) => {
        dispatch(InfoActions.fail(err));
      });
  };
};

export const listVoucherCode = (filterOptions, isExport = false) => {
  return (dispatch) => {
    let url = `/voucher/batchcode/${filterOptions.BatchID}/code?page_number=${
      filterOptions.currentPage
    }&page_size=${filterOptions.page_size}${
      filterOptions.status.value !== ""
        ? "&status=" + filterOptions.status.value
        : ""
    }${
      filterOptions.isLock.value !== ""
        ? "&isLock=" + filterOptions.isLock.value
        : ""
    }${
      filterOptions.isVoid.value !== ""
        ? "&isVoid=" + filterOptions.isVoid.value
        : ""
    }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
      filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
    }${filterOptions.searchKeyword}`;
    dispatch(ListActions.start(isExport));
    service
      .get(url)
      .then((res) => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch((err) => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};

export const listVoucherCodePrint = (filterOptions, refId) => {
  return (dispatch) => {
    let url =
      `/voucher/batchcode/${filterOptions.BatchID}/code/print` +
      `?page_number=${filterOptions.currentPage}&page_size=${
        filterOptions.page_size
      }&refId=${refId}${
        filterOptions.status.value !== ""
          ? "&status=" + filterOptions.status.value
          : ""
      }${
        filterOptions.isLock.value !== ""
          ? "&isLock=" + filterOptions.isLock.value
          : ""
      }${
        filterOptions.isVoid.value !== ""
          ? "&isVoid=" + filterOptions.isVoid.value
          : ""
      }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
        filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
      }${filterOptions.searchKeyword}`;
    dispatch(PrintActions.start());
    console.log(url);
    service
      .get(url)
      .then((res) => {
        dispatch(PrintActions.success(res.data, filterOptions, false));
      })
      .catch((err) => {
        dispatch(PrintActions.fail(err, filterOptions));
      });
  };
};
