import classNames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector, connect } from "react-redux";
import { NavLink } from "react-router-dom";
import MainMenu from "../../MainMenu";
import SideMenu from "../../SideMenu";
import newVoucherAPI from "../../containers/NewVouchers/NewVoucher.api";
import { isMainMenuSelected } from "../../helpers/utility";
import {updateApprovalListCount} from "../../store/actions/voucherApproval"

const Sidebar = (props) => {
  let allowedSideMenu = [];
  let allowedMainMenu = [];
  let userPermissions = [];
  let api = newVoucherAPI();

  const userInfo = useSelector((state) => state.auth.userInfo.userType);
  const approvalListStoreCount = useSelector((state) => state.voucherApproval.approvalListCount);
  userPermissions = props.userInfo.permissions
    ? props.userInfo.permissions.split(",")
    : null;

  allowedSideMenu = SideMenu.filter((f) => {
    if (f.permission.includes(",")) {
      const permissions = f.permission.split(",");
      const matchingPermission = permissions.find((p) => userPermissions.includes(p));
      if (matchingPermission) {
        return matchingPermission;
      }
    } else {
      return userPermissions.includes(f.permission);
    }
  });

  const menuItemsAsPessmision = MainMenu.filter(
    (item) => _.intersection(item.permissions, props.persmissions).length > 0
  );

  // Usage example:
  async function fetchData() {
    const response = await api.getApprovalListCount();
    if (response.success) {
      props.updateApprovalListCount(response.count);
    } else {
    }
  }

  // Call the fetchData function to initiate the request
  fetchData();

  let sideBarMenues = (MainMenu) => {
    return allowedSideMenu.filter((item) => {
      if (userInfo === "Approver" && item.title === "Templates") {
        return false; // Skip rendering "Templates" for "Approver"
      } else if (userInfo === "Creator" && item.title === "Approval") {
        return false; // Skip rendering "Approval" for "Creator"
      } else if (
        userInfo === "SuperAdminIT" &&
        item.title !== "Vouchers List"
      ) {
        return false; // Don't show anything for SuperAdminIT
      }
      return item.menu && item.main.toLowerCase() === MainMenu.toLowerCase();
    });
  };

  let menuItems = menuItemsAsPessmision.map((item, index) => {
    return (
      <li
        key={index}
        className={classNames({
          active: isMainMenuSelected(item),
        })}
      >
        <a
          href={`#${item.title}`}
          data-toggle="collapse"
          aria-expanded="false"
          className="dropdown-toggle"
        >
          {item.title}
        </a>

        <ul className="collapse list-unstyled" id={item.title}>
          {sideBarMenues(item.title).map((item, index) => {
            return (
              <li key={index}>
                {/* <NavLink to={item.path} onClick={props.onMenuClick}> */}
                {/* {item.title} */}
                <NavLink to={item.path} onClick={props.onMenuClick}>
                  {item.title === "Approval"
                    ? `Approval List (${approvalListStoreCount})`
                    : item.title}
                </NavLink>
                {/* </NavLink> */}
              </li>
            );
          })}
        </ul>
      </li>
    );
  });

  return menuItems;
};

const mapStateToProps = (state) => ({
  approvalListCount: state.approvalListCount,
});

const mapDispatchToProps = {
  updateApprovalListCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

