import service from "../../services/voucher.service";
import * as actionTypes from "./actionTypes";
import infoActions from "../../helpers/infoActions";

const AvailableProductsActions = infoActions("PRODUCT_LIMIT_TO_VOUCHER_LIST");

export const addProductLimitToVoucher = product => {
  return {
    type: actionTypes.ADD_PRODUCT_LIMIT_TO_VOUCHER,
    product: product
  };
};

export const removeProductLimitToVoucher = productId => {
  return {
    type: actionTypes.REMOVE_PRODUCT_LIMIT_TO_VOUCHER,
    productId: productId
  };
};

export const selectProductLimitToVoucher = productId => {
  return {
    type: actionTypes.SELECT_PRODUCT_LIMIT_TO_VOUCHER,
    selectedProductLimitToVoucher: productId
  };
};

export const getProductLimitToVoucher = voucherId => {
  return dispatch => {
    dispatch(AvailableProductsActions.start());
    service
      // .get(`product/${voucherId}/limitedToVouchers`)
      .get(`voucher/${voucherId}/products/exclude`)
      .then(res => {
        dispatch(AvailableProductsActions.success(res.data));
      })
      .catch(err => {
        dispatch(AvailableProductsActions.fail(err));
      });
  };
};
