import React from "react";
import { Link } from "react-router-dom";
const BackButton = props => {
  return (
    <>
      <Link to={props.page}>
        <button type="button" className="btn btn-dark">
          Back
        </button>
      </Link>
    </>
  );
};
export default BackButton;
