import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  availableRoleList: null,
  error: null,
  loading: false,
  selectedRole: null
};

const availableRoleStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const availableRoleSuccess = (state, action) => {
  return updateObject(state, {
    availableRoleList: action.roleList,
    loading: false,
    selectedRole: null,
    error: null
  });
};

const availableRoleFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const addAvailableRole = (state, action) => {
  let newRole = {
    r_id: action.selectedRole.ur_r_id,
    r_title: action.selectedRole.r_title
  };
  return {
    ...state,
    availableRoleList: state.availableRoleList.concat(newRole)
  };
};

const removeAvailableRole = (state, action) => {
  let availableRoles = state.availableRoleList
    .filter(e => e.r_id !== action.roleId)
    .map(item => {
      return item;
    });
  return {
    ...state,
    availableRoleList: availableRoles,
    selectedRole: null
  };
};

const selectAvailableRole = (state, action) => {
  return updateObject(state, {
    selectedRole: action.selectedRoles
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AVAILABLE_ROLE_SUCCESS:
      return availableRoleSuccess(state, action);
    case actionTypes.AVAILABLE_ROLE_FAIL:
      return availableRoleFail(state, action);
    case actionTypes.AVAILABLE_ROLE_START:
      return availableRoleStart(state, action);
    case actionTypes.AVAILABLE_ROLE_SELECT:
      return selectAvailableRole(state, action);
    case actionTypes.AVAILABLE_ROLE_ADD:
      return addAvailableRole(state, action);
    case actionTypes.AVAILABLE_ROLE_REMOVE:
      return removeAvailableRole(state, action);
    default:
      return state;
  }
};

export default reducer;
