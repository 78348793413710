import * as actionTypes from "../actions/actionTypes";
import InfoReducer from "../../helpers/infoReducer";

const infoReducer = InfoReducer("addStatus");

const initialState = {
  info: null,
  loading: false,
  message: "",
  selectedRole: null,
  addStatus: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_VOUCHER_START:
      return infoReducer.start(state);
    case actionTypes.ADD_VOUCHER_SUCCESS:
      return infoReducer.success(state, action);
    case actionTypes.ADD_VOUCHER_FAIL:
      return infoReducer.fail(state, action);
    case actionTypes.EDIT_VOUCHER_START:
      return infoReducer.start(state);
    case actionTypes.EDIT_VOUCHER_SUCCESS:
      return infoReducer.success(state, action);
    case actionTypes.EDIT_VOUCHER_FAIL:
      return infoReducer.fail(state, action);
    case actionTypes.EXTEND_VOUCHER_START:
      return infoReducer.start(state);
    case actionTypes.EXTEND_VOUCHER_SUCCESS:
      return infoReducer.success(state, action);
    case actionTypes.EXTEND_VOUCHER_FAIL:
      return infoReducer.fail(state, action);
    default:
      return state;
  }
};

export default reducer;
