import React, { Component } from "react";
import { hasPermision } from "../helpers/utility";
import { PermissionsContext } from "../hoc/PermissionsContext";
import * as mapPermission from "../helpers/MapPermissions";

class Permission extends Component {
  static contextType = PermissionsContext;

  render() {
    return (
      <>
        {hasPermision(this.context, mapPermission[this.props.p]) && (
          <>{this.props.children}</>
        )}
      </>
    );
  }
}

export default Permission;
