import service from "../../services/voucher.service";
import infoActions from "../../helpers/infoActions";
const InfoActions = infoActions("LOAD_VOUCHER_INFO");
const InfoActionsStatus = infoActions("CHANGE_STATUS_VOUCHER_INFO");

export const loadVoucherInfo = id => {
  return dispatch => {
    dispatch(InfoActions.start());
    service
      .get(`/voucher/${id}`)
      .then(res => {
        dispatch(InfoActions.success(res.data));
      })
      .catch(err => {
        dispatch(InfoActions.fail(err));
      });
  };
};

export const changeVoucherStatus = (id, status) => {
  return dispatch => {
    dispatch(InfoActionsStatus.start());
    service
      .post(`/voucher/${id}/changestatus`, { VoucherStatus: status })
      .then(res => {
        dispatch(loadVoucherInfo(id));
      })
      .catch(err => {
        dispatch(InfoActionsStatus.fail(err));
      });
  };
};
