import React from "react";
import Select from "react-select";
import classNames from "classnames";
import { isNotNull } from "../helpers/utility";
const Dropdown = (props) => {
  return (
    <div className="form-group">
      {props.label !== null &&
      props.label !== undefined &&
      props.label !== "" ? (
        <label className="">{props.label}</label>
      ) : null}
      <Select
        name={props.name && props.name}
        isDisabled={props.disabled !== undefined ? props.disabled : ""}
        className={classNames({
          "placeholder-shown": true,
          "Is-Invalid": !props.valid && props.isSubmited,
        })}
        isClearable={props.isClearable}
        inputId={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={(e) => props.onBlur && props.onBlur(e)}
        options={props.options}
        {...props}
      />
      {/* {props.valid === false && props.isSubmited === true ? (
        <div className="alert alert-danger" role="alert">
          {props.validMessage} is required
        </div>
      ) : null} */}
    </div>
  );
};

export default Dropdown;
