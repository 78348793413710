import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  error: null,
  loading: false,
  u_id: 0,
  u_fullName: ""
};

const loadUserInfoStart = (state, action) => {
  console.log("listUserStart");
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const loadUserInfoSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    u_id: action.userInfo.u_id,
    u_fullname: action.userInfo.u_fullname,
    u_org_id: action.userInfo.u_org_id,
    u_type: action.userInfo.u_type,
    u_status: action.userInfo.u_status,
    u_username: action.userInfo.u_username,
    u_email: action.userInfo.u_email,
    u_phone: action.userInfo.u_phone,
    u_mfa: action.userInfo.u_mfa,
    u_country_id: action.userInfo.u_country_id,
    u_age_group: action.userInfo.u_age_group,
    u_staffNo: action.userInfo.u_staffNo,
    u_authentication_provider: action.userInfo.u_authentication_provider,
    u_password_expiry: action.userInfo.u_password_expiry,
    u_locked: action.userInfo.u_locked,
    u_modifiedDate: action.userInfo.u_modifiedDate
  });
};

//
const changeUserStatusStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const changeUserStatusSuccess = (state, action) => {
  console.log("changeUserStatusSuccess");
  return updateObject(state, {
    u_status: state.u_status === "active" ? "deactive" : "active",
    loading: false
  });
};

const changeUserStatusFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

//-------------------------------------------------------------

const loadUserInfoFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_USERINFO_SUCCESS:
      return loadUserInfoSuccess(state, action);
    case actionTypes.LOAD_USERINFO_FAIL:
      return loadUserInfoFail(state, action);
    case actionTypes.LOAD_USERINFO_START:
      return loadUserInfoStart(state, action);
    case actionTypes.CHANGE_USER_STATUS_SUCCESS:
      return changeUserStatusSuccess(state, action);
    case actionTypes.CHANGE_USER_STATUS_FAIL:
      return changeUserStatusFail(state, action);
    case actionTypes.CHANGE_USER_STATUS_START:
      return changeUserStatusStart(state, action);
    default:
      return state;
  }
};

export default reducer;
