import service from "../../services/voucher.service";
import infoActions from "../../helpers/infoActions";
const InfoActions = infoActions("LOAD_VOUCHER_CODE_INFO");
const VoidActions = infoActions("VOID_VOUCHER_CODE_INFO");
const VoidRangeActions = infoActions("VOID_RANGE_VOUCHER_CODE_INFO");

export const loadVoucherCodeChecker = code => {
  return dispatch => {
    dispatch(InfoActions.start());
    service
      .get(`/voucher/vouchercodeinfo/${code}`)
      .then(res => {
        res.data["searchKeyword"] = code;
        dispatch(InfoActions.success(res.data));
      })
      .catch(err => {
        dispatch(InfoActions.fail(err));
      });
  };
};

export const voidVoucherCodeChecker = (id, code) => {
  return dispatch => {
    dispatch(VoidActions.start());
    service
      .post(`/voucher/voucherbatch/vouchercode/void`, {
        VoucherCodesID: [id]
      })
      .then(res => {
        // dispatch(VoidActions.success(res.data));
        dispatch(loadVoucherCodeChecker(code));
      })
      .catch(err => {
        dispatch(VoidActions.fail(err));
      });
  };
};

export const voidRangeVoucherCodeChecker = range => {
  return dispatch => {
    dispatch(VoidRangeActions.start());
    service
      .post(`/voucher/voucherbatch/vouchercode/voidrange`, {
        SerialRange: range
      })
      .then(res => {
        // dispatch(VoidRangeActions.success(res.data));
        dispatch(loadVoucherCodeChecker(range));
      })
      .catch(err => {
        dispatch(VoidRangeActions.fail(err));
      });
  };
};
