import service from "../../services/partnerManagement.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_PARTNER_APPLICATION");

export const selectPartnerApplication = row => {
  return {
    type: actionTypes.LIST_PARTNER_APPLICATION_SELECT,
    selectedRow: row
  };
};

export const listPartnerInbox = (filterOptions, isExport = false) => {
  console.log(
    `/partner/application?page_number=${filterOptions.currentPage}&page_size=${
      filterOptions.page_size
    }&application_status=${filterOptions.applicationStatus.value}&sort_by=${
      filterOptions.sort_by
    }&sort_dir=${filterOptions.sort_dir}${
      filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
    }${filterOptions.searchKeyword}`
  );
  return dispatch => {
    dispatch(ListActions.start(isExport));
    service
      .get(
        `/partner/application?page_number=${
          filterOptions.currentPage
        }&page_size=${filterOptions.page_size}&application_status=${
          filterOptions.applicationStatus.value
        }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
          filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
        }${filterOptions.searchKeyword}`
      )
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
