import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  listData: null,
  listExport: null,
  error: null,
  loading: false,
  loadingExport: false,
  userType: { value: "ERLAdmin", label: "ERLAdmin" },
  org: { value: "1", label: "ERL" },
  sort_by: "u_fullName",
  sort_dir: "asc",
  searchKeyword: "",
  selectedRow: null,
  currentPage: 1,
  total: 0
};

const listUserExportStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loadingExport: true,
    listExport: null
  });
};

const listUserExportSuccess = (state, action) => {
  return updateObject(state, {
    listExport: action.list,
    loadingExport: false,
    error: null
  });
};

const listUserStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    listExport: null
  });
};

const listUserSuccess = (state, action) => {
  return updateObject(state, {
    listData: action.list,
    userType: action.filterOptions.userType,
    loading: false,
    org: action.filterOptions.org,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    selectedRow: null,
    currentPage: action.filterOptions.currentPage,
    total: action.total,
    error: null,
    loadingExport: false,
    listExport: null
  });
};

const listUserFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    userType: action.filterOptions.userType,
    org: action.filterOptions.org,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    currentPage: action.filterOptions.currentPage,
    listExport: null,
    error: action.error
  });
};

const selectUser = (state, action) => {
  return updateObject(state, {
    selectedRow: action.selectedRow //updateMultiSelect(state.selectedUsers, action.selectedUsers)
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_USERS_SUCCESS:
      return listUserSuccess(state, action);
    case actionTypes.LIST_USERS_FAIL:
      return listUserFail(state, action);
    case actionTypes.LIST_USERS_START:
      return listUserStart(state, action);
    case actionTypes.LIST_USERS_SELECT:
      return selectUser(state, action);
    case actionTypes.LIST_USERS_EXPORT_START:
      return listUserExportStart(state, action);
    case actionTypes.LIST_USERS_EXPORT_SUCCESS:
      return listUserExportSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
