import React from "react";
//var classNames = require('classnames');

const celCheckbox = props => {
  let ckeck = () => {
    if (props.checked) {
      return <input type="checkbox" className="form-check-input" checked />;
    } else {
      return <input type="checkbox" className="form-check-input" />;
    }
  };
  return (
    <td>
      <div className="form-check">{ckeck()}</div>
    </td>
  );
};

export default celCheckbox;
