import decode from "jwt-decode";
import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const authSuccess = (token) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    accessToken: token,
    userInfo: decode(token).payload,
  };
};

export const forgotPasswordSuccess = (token) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_END_EMAIL_SUCCESS,
  };
};

export const forgotPasswordFail = (token) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_END_EMAIL_FAIL,
  };
};

export const forgotPasswordResetTokenStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_TOKEN_START,
  };
};

export const forgotPasswordResetTokenSuccess = (data) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS,
    isValid: data.isValid,
  };
};

export const forgotPasswordResetTokenFail = (data) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_CHECK_TOKEN_FAIL,
  };
};

export const forgotPasswordResetStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_START,
  };
};

export const forgotPasswordResetSuccess = (data) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_SUCCESS,
  };
};

export const forgotPasswordResetFail = (data) => {
  return {
    type: actionTypes.FORGOT_PASSWORD_RESET_FAIL,
  };
};

//

export const loginChangePasswordSart = () => {
  return {
    type: actionTypes.LOGIN_CHANGE_PASSWORD_START,
  };
};

export const loginChangePasswordSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_CHANGE_PASSWORD_SUCCESS,
    //isValid: data.isValid
  };
};

export const loginChangePasswordFail = (data) => {
  return {
    type: actionTypes.LOGIN_CHANGE_PASSWORD_FAIL,
  };
};

//
export const forgotPasswordStart = () => {
  return {
    type: actionTypes.FORGOT_PASSWORD_END_EMAIL_START,
  };
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const authFail = (data) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    errorDate: data,
  };
};

export const setloginStatus = (data) => {
  return {
    type: actionTypes.LOGIN_SET_STATUS,
    errorDate: data,
  };
};

export const setloginLockedStatus = (data) => {
  return {
    type: actionTypes.LOGIN_SET_LOCKED_STATUS,
    errorDate: data,
  };
};

export const setloginExpiredStatus = (data) => {
  return {
    type: actionTypes.LOGIN_SET_EXPIRED_STATUS,
    userInfo: data.u_username,
  };
};

export const setloginMfaStatus = (data, mfaSessionKey) => {
  return {
    type: actionTypes.LOGIN_SET_MFA_STATUS,
    mfaSessionKey: mfaSessionKey,
    errorDate: data,
  };
};

export const forgotPasswordStatus = (status) => {
  return {
    type: actionTypes.LOGIN_SET_FORGOTPASSWORD_STATUS,
    forgotPassword: status,
  };
};

export const forgotPasswordCleanMessage = (status) => {
  return {
    type: actionTypes.RESET_PASSWORD_END_CLEAN_MESSAGE,
  };
};

export const sendForgotPasswordEmail = (email) => {
  return (dispatch) => {
    dispatch(forgotPasswordStart());
    const params = {
      email: email,
    };
    service
      .post("/user/forgotpassword", params)
      .then((res) => {
        dispatch(forgotPasswordSuccess(res.data));
      })
      .catch((err) => {
        dispatch(forgotPasswordFail(err.message));
      });
  };
};

export const resetForgotPassword = (password, token) => {
  return (dispatch) => {
    dispatch(forgotPasswordResetStart());
    const params = {
      password: password,
      token: token,
    };
    service
      .post("/user/forgotpassword/changepassword", params)
      .then((res) => {
        dispatch(forgotPasswordResetSuccess(res.data));
      })
      .catch((err) => {
        dispatch(forgotPasswordResetFail(err.message));
      });
  };
};

export const changeForgotPassword = (params) => {
  return (dispatch) => {
    dispatch(forgotPasswordResetStart());
    service
      .post("/user/changepasswordwithoutlogin", params)
      .then((res) => {
        dispatch(forgotPasswordResetSuccess(res.data));
      })
      .catch((err) => {
        dispatch(forgotPasswordResetFail(err.message));
      });
  };
};

export const checkForgotPasswordResetToken = (token) => {
  return (dispatch) => {
    dispatch(forgotPasswordResetTokenStart());
    const params = {
      token: token,
    };
    service
      .post("/user/forgotpassword/verifytoken", params)
      .then((res) => {
        dispatch(forgotPasswordResetTokenSuccess(res.data));
      })
      .catch((err) => {
        dispatch(forgotPasswordResetTokenFail(err.message));
      });
  };
};

export const logout = () => {
  console.log("logout");
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  //localStorage.removeItem("userInfo");
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
};

export const backToLoginPage = () => {
  return {
    type: actionTypes.RESET_PASSWORD_BACK_TO_LOGIN,
  };
};

export const login = (username, password) => {
  return (dispatch) => {
    const params = {
      u_username: username,
      u_password: password,
    };
    dispatch(loginStart());
    service
      .post("/user/login", params)
      .then((res) => {
        dispatch(loginStart(res.data.startLogin));
        if (res.data.loginStatus.toString() !== "true") {
          if (res.data.loginStatus.toString() === "mfa") {
            dispatch(setloginMfaStatus(res.data, res.data.loginSessionKey));
          } else if (res.data.loginStatus.toString() === "false") {
            dispatch(authFail(res.data));
          } else if (res.data.loginStatus.toString() === "locked") {
            dispatch(setloginLockedStatus(res.data));
          } else if (res.data.loginStatus.toString() === "expired") {
            //let accessToken = decode(res.data.accessToken);
            //console.log({ accessToken });
            dispatch(setloginExpiredStatus(params));
          }
        } else {
          dispatch(setloginStatus(res.data));
          let jsonJwt = decode(res.data.accessToken);
          const expirationDate = new Date(
            new Date().getTime() + jsonJwt.exp * 1000,
          );
         

          localStorage.setItem("token", res.data.accessToken);
          localStorage.setItem("expirationDate", expirationDate);
          dispatch(authSuccess(res.data.accessToken));
          dispatch(checkAuthTimeout(jsonJwt.exp));
        }
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const mfaLogin = (sessionKey, otp) => {
  return (dispatch) => {
    const params = {
      session_key: sessionKey,
      otp: otp,
    };
    dispatch(loginStart());
    service
      .post("/user/mfalogin", params)
      .then((res) => {
        if (res.data.loginStatus.toString() !== "true") {
          dispatch(setloginStatus(res.data));
        } else {
          let jsonJwt = decode(res.data.accessToken);
          const expirationDate = new Date(
            new Date().getTime() + jsonJwt.exp * 1000,
          );
          console.log('res->>',res.data)
          localStorage.setItem("token", res.data.accessToken);
          localStorage.setItem("expirationDate", expirationDate);
          dispatch(authSuccess(res.data.accessToken));
          dispatch(checkAuthTimeout(jsonJwt.exp));
          dispatch(setloginMfaStatus(res.data, null));
        }
      })
      .catch((err) => {
        dispatch(authFail(err.message));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        //const userInfo = localStorage.getItem("userInfo");
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000,
          ),
        );
      }
    }
  };
};
