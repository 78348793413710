import * as actionTypes from "../actions/actionTypes";
import InfoReducer from "../../helpers/infoReducer";

const infoReducer = InfoReducer("addStatus");

const initialState = {
  loading: false,
  message: "",
  addStatus: null,
  result: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TRAIN_STATUS_DETAIL_START:
      return infoReducer.start(state);
    case actionTypes.ADD_TRAIN_STATUS_DETAIL_SUCCESS:
      return infoReducer.success(state, action);
    case actionTypes.ADD_TRAIN_STATUS_DETAIL_FAIL:
      return infoReducer.fail(state, action);
    case actionTypes.EDIT_TRAIN_STATUS_DETAIL_START:
      return infoReducer.start(state);
    case actionTypes.EDIT_TRAIN_STATUS_DETAIL_SUCCESS:
      return infoReducer.success(state, action);
    case actionTypes.EDIT_TRAIN_STATUS_DETAIL_FAIL:
      return infoReducer.fail(state, action);
    default:
      return state;
  }
};

export default reducer;
