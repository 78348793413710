import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import decode from "jwt-decode";

const axiosInterceptors = (WrappedComponent, axios) => {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        errorMessage: null,
        errorStatus: null,
      };
    }

    expireTokenRedirect;

    isAuthenticated = () => {
      if (localStorage.getItem("token")) {
        var { exp } = decode(localStorage.getItem("token"));
        if (Date.now() / 1000 > exp) {
          this.expireTokenRedirect = <Redirect to="/logout" />;
        }
      }
    };

    componentWillMount() {
      this.isAuthenticated();
      this.reqInterceptor = axios.interceptors.request.use((req) => {
        this.isAuthenticated();
        req.headers["x-api-key"] =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJhZG1pbnBvcnRhbCIsImlhdCI6MTYzODYxNTU3N30.72e_5AhLNAWrpNW7EJo0ouwqyoNQ9gYOC8TazNirXZY";
        if (localStorage.getItem("token")) {
          req.headers.sessiontoken = localStorage.getItem("token");
          req.validateStatus = function (status) {
            return status >= 200 && status < 400;
          };
        }
        this.setState({ errorMessage: null, errorStatus: null });
        return req;
      });

      this.resInterceptor = axios.interceptors.response.use(
        (res) => {
          //
          // this.setState({ errorMessage: error.response.data, errorStatus: "success" });
          return res;
        },
        (error) => {
          if (error.response && error.response.status !== 404) {
            this.setState({
              errorMessage:
                error.response.data.error !== undefined
                  ? error.response.data.error
                  : error.response.data,
              errorStatus: "danger",
            });
          } else {
            this.setState({
              errorMessage: error.message,
              errorStatus: "danger",
            });
          }
          return Promise.reject(error);
        },
      );
    }

    componentWillUnmount() {
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    errorConfirmedHandler = () => {
      this.setState({ error: null });
    };

    render() {
      return (
        <>
          {this.expireTokenRedirect}
          {/* <Modal
            show={this.state.error}
            modalClosed={this.errorConfirmedHandler}
          >
            {this.state.error ? this.state.error.message : null}
          </Modal> */}
          <WrappedComponent
            errorMessage={this.state.errorMessage}
            errorStatus={this.state.errorStatus}
            {...this.props}
          />
        </>
      );
    }
  };
};

export default axiosInterceptors;
