import React from "react";

const Notfound = props => {
  return (
    <div>
      &nbsp;
      <h2>Not found 404</h2>
    </div>
  );
};

export default Notfound;
