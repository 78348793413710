import * as actionTypes from "../actions/actionTypes";
import {
  updateObject,
  permissionGroupBy,
  updateMultiSelect
} from "../../helpers/utility";

const initialState = {
  permissionList: null,
  permissionCategoryList: null,
  error: null,
  loading: false,
  sort_by: "p_title",
  sort_dir: "asc",
  searchKeyword: "",
  data: null,
  selectedPermission: null,
  selectedPermissionCategory: 0,
  currentPage: 1,
  total: 0,
  errorMessage: null,
  addedRole: null
};

const resetForm = (state, action) => {
  return updateObject(state, {
    errorMessage: null
  });
};
const listPermissionStart = (state, action) => {
  console.log("listPermissionStart");
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const updateRolesPermissionsSuccess = (state, action) => {
  return updateObject(state, {
    errorMessage: "Update role success.",
    addedRole: action.addedRole,
    errorMessage: null,
    loading: false
  });
};

const addRolesPermissionsSuccess = (state, action) => {
  return updateObject(state, {
    errorMessage: "Add role success.",
    addedRole: action.addedRole,
    errorMessage: null,
    loading: false
  });
};

const listPermissionSuccess = (state, action) => {
  console.log(
    "listPermissionSuccess reduucer--->: " +
      JSON.stringify(action.permissionList, null, 2)
  );

  var data = permissionGroupBy(action.permissionList, "pcat_title");
  return updateObject(state, {
    data: action.permissionList,
    permissionList: data[0].permissions,
    permissionCategoryList: data,
    loading: false,
    //data[0].permissions
    selectedPermission: action.permissionList
      .filter(e => e.isSelected === "true")
      .map(item => {
        return item.p_id;
      }),
    selectedPermissionCategory: data[0].pcat_id,
    error: null
  });
};

const listPermissionFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const searchboxChange = (state, action) => {
  console.log("listpermission Search change Reducer");
  return updateObject(state, {
    searchKeyword: action.keyword
  });
};

const updateDta = (data, selectedPermission) => {
  return data.map(item => {
    return item.p_id === selectedPermission
      ? { ...item, isSelected: item.isSelected === "true" ? "false" : "true" }
      : item;
  });
};

const selectPermission = (state, action) => {
  console.log("Select permission Reducer " + action.selectedPermission);
  return updateObject(state, {
    selectedPermission: updateMultiSelect(
      state.selectedPermission,
      action.selectedPermission
    ),
    data: updateDta(state.data, action.selectedPermission)
  });
};

const updateSelectedpermission = data => {
  console.log("updateSelectedpermissionData: " + JSON.stringify(data, null, 2));
  let d = data
    .filter(e => e.isSelected === "true")
    .map(item => {
      return item.p_id;
    });
  console.log("updateSelectedpermissionData: " + JSON.stringify(d));
  return d;
};

const selectPermissionCategory = (state, action) => {
  let permissionList = permissionGroupBy(state.data, "pcat_title").filter(
    e => e.pcat_id == action.selectedPermissionCategory
  )[0].permissions;
  return updateObject(state, {
    selectedPermissionCategory: action.selectedPermissionCategory,
    permissionList: permissionList,
    selectedPermission: updateSelectedpermission(state.data)
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_PERMISSIONS_SUCCESS:
      return listPermissionSuccess(state, action);
    case actionTypes.LIST_PERMISSIONS_FAIL:
      return listPermissionFail(state, action);
    case actionTypes.LIST_PERMISSIONS_START:
      return listPermissionStart(state, action);
    case actionTypes.LIST_PERMISSIONS_SEARCHBOX_CHANGE:
      return searchboxChange(state, action);
    case actionTypes.LIST_PERMISSIONS_SELECT:
      return selectPermission(state, action);
    case actionTypes.LIST_PERMISSIONS_Category_SELECT:
      return selectPermissionCategory(state, action);
    case actionTypes.UPDATE_ROLES_PPERMISSIONS_SUCCESS:
      return updateRolesPermissionsSuccess(state, action);
    case actionTypes.ADD_ROLES_PPERMISSIONS_SUCCESS:
      return addRolesPermissionsSuccess(state, action);
    case actionTypes.LIST_PERMISSIONS_RESETFORM:
      return resetForm(state, action);
    default:
      return state;
  }
};

export default reducer;
