import service from "../../services/partnerManagement.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_PARTNER_APP_ATTACHMENT");

export const selectlistPartnerAppAttachment = row => {
  return {
    type: actionTypes.LIST_USER_ACTIVITY_SELECT,
    selectedRow: row
  };
};

export const listPartnerAppAttachment = (filterOptions, isExport = false) => {
  const url = `/partner/application/${
    filterOptions.applicationId
  }/uploadAttachment?page_number=${filterOptions.currentPage}&page_size=${
    filterOptions.page_size
  }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
    filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
  }${filterOptions.searchKeyword}`;
  console.log(url);
  return dispatch => {
    dispatch(ListActions.start(isExport));
    service
      .get(url)
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
