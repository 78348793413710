import React from "react";

const Col = props => {
  return (
    <div
      className={props.className ? props.className : "col"}
      style={{ ...props.style }}
    >
      {props.children}
    </div>
  );
};
export default Col;
