import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const accessReportStart = () => {
  return {
    type: actionTypes.ACCESS_REPORT_START
  };
};

export const accessReportSuccess = result => {
  return {
    type: actionTypes.ACCESS_REPORT_SUCCESS,
    reportResult: result
  };
};

export const accessReportFail = err => {
  return {
    type: actionTypes.ACCESS_REPORT_FAIL,
    error: err
  };
};

export const loadAccessReport = u_type => {
  return dispatch => {
    dispatch(accessReportStart());
    service
      .get(`/report/access?u_type=${u_type.value}`)

      .then(res => {
        console.log("Access Report Action Success");
        // console.dir(res);
        if (res !== undefined) {
          dispatch(accessReportSuccess(res.data));
        }
      })
      .catch(err => {
        console.log("Access Report Action Error");
        console.error(err);
        return Promise.reject(err);
      });
  };
};
