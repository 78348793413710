import { updateObject } from "./utility";

const addEditReducer = name => {
  const start = state => {
    return updateObject(state, {
      loading: true,
      result: ""
    });
  };

  const success = (state, action) => {
    return updateObject(state, {
      [name]: action.info,
      loading: false,
      result: "success"
    });
  };

  const fail = (state, action) => {
    return updateObject(state, {
      message: action.error,
      loading: false,
      result: "fail"
    });
  };

  return {
    start,
    success,
    fail
  };
};

export default addEditReducer;
