import * as actionTypes from "../actions/actionTypes";
import InfoReducer from "../../helpers/infoReducer";
import { updateObject } from "../../helpers/utility";

const objName = "codeInfo";
const infoReducer = InfoReducer(objName);

const initialState = {
  info: {},
  loading: false,
  message: "",
  status: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VOUCHER_CODE_INFO_START:
      return infoReducer.start(state);
    case actionTypes.LOAD_VOUCHER_CODE_INFO_SUCCESS:
      return infoReducer.success(state, action);
    case actionTypes.LOAD_VOUCHER_CODE_INFO_FAIL:
      return infoReducer.fail(state, action);
    default:
      return state;
  }
};

export default reducer;
