import _ from "lodash";
import { decode as atob } from "base-64";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const hasPermision = (persmissions, allowPermision) => {
  if (persmissions) {
    return persmissions.filter((f) => f == allowPermision).length > 0
      ? true
      : false;
  } else {
    return false;
  }
};

export const permissionGroupBy = (list, field) => {
  var result = _(list)
    .groupBy("pcat_title")
    .map((group, category) => ({
      pcat_id: group[0].pcat_id,
      pcat_title: category,
      permissions: group.map(({ p_title, p_id, isSelected }) => ({
        p_title,
        p_id,
        isSelected,
      })),
    }))
    .value();
  return result;
};

export const isNotNull = (value) => {
  return value === null || value === "" || value === undefined ? false : true;
};
export const updateMultiSelect = (stateobj, actionobj) => {
  if (stateobj === null) {
    return [actionobj];
  } else {
    if (stateobj.filter((item) => item === actionobj).length === 0) {
      return [...stateobj, actionobj];
    } else {
      return stateobj.filter((item) => item !== actionobj);
    }
  }
};

export const isMainMenuSelected = (menuItem) => {
  const pathArray = window.location.pathname
    .split(/[/,?]+/)
    .filter((item) => item !== "");

  return (
    pathArray !== undefined &&
    _.isArray(pathArray) &&
    pathArray.length > 0 &&
    pathArray[0].toLowerCase() === menuItem.main.toLowerCase()
  );
};

export const isSideMenuSelected = (menuItem) => {
  const pathArray = window.location.pathname
    .split(/[/,?]+/)
    .filter((item) => item !== "");

  return (
    pathArray !== undefined &&
    _.isArray(pathArray) &&
    pathArray.length > 1 &&
    pathArray[0].toLowerCase() === menuItem.main.toLowerCase() &&
    pathArray[1].toLowerCase() === menuItem.sub.toLowerCase()
    // pathArray.filter(item => {
    //   return menuItem.path.replace(/\//g, "") === item;
    // }).length > 0
  );
};

export const currentMainMenu = () => {
  const pathArray = window.location.pathname
    .split(/[/,?]+/)
    .filter((item) => item !== "");

  return pathArray !== undefined && _.isArray(pathArray) && pathArray.length > 0
    ? pathArray[0].toLowerCase()
    : "dashboard";
};

export const openFile = (base64, type) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], {
    type,
  });
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
};

export const isNewSystemBarcode = (barcode) => {
  const barcode_len = barcode.length;
  if (barcode_len > 20) {
    if (barcode_len > 40) {
      return false;
    } else {
      return true;
    }
  } else if (barcode_len < 20) {
    if (barcode_len >= 10) {
      return true;
    } else {
      return false;
    }
  }
};
