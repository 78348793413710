import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const listOrgMngSuccess = (List, filterOptions) => {
  const Total = Array.isArray(List) ? List.pop().Total : 0;
  return {
    type: actionTypes.LIST_ORG_MNG_SUCCESS,
    orgList: List,
    loading: false,
    filterOptions: filterOptions,
    total: Total
  };
};

export const listOrgMngFail = error => {
  return {
    type: actionTypes.LIST_ORG_MNG_FAIL,
    error: error,
    loading: false
  };
};

export const listOrgMngLoading = () => {
  return {
    type: actionTypes.LIST_ORG_MNG_START,
    loading: true
  };
};

export const selectOrg = org => {
  return {
    type: actionTypes.LIST_ORG_MNG_SELECT,
    selectedOrg: org
  };
};

export const clearOrgMngSelected = org => {
  return {
    type: actionTypes.LIST_ORG_MNG_SELECT
  };
};

export const addOrgMngLoading = () => {
  return {
    type: actionTypes.ADD_ORG_MNG_START
  };
};

export const addOrgMngSuccess = data => {
  console.log("ADD EDIT ORG - Action success");
  return {
    type: actionTypes.ADD_ORG_MNG_SUCCESS
  };
};

export const listOrgMngExportLoading = () => {
  return {
    type: actionTypes.LIST_ORG_MNG_EXPORT_START
  };
};

export const listOrgMngExportSuccess = List => {
  const Total = Array.isArray(List) ? List.pop().Total : 0;
  return {
    type: actionTypes.LIST_ORG_MNG_EXPORT_SUCCESS,
    orgExportList: List
  };
};

export const listOrgMng = filterOptions => {
  console.log("OrgListLoad - Action Start");
  console.dir(filterOptions);
  return dispatch => {
    dispatch(listOrgMngLoading());
    service
      .get(
        `/organization?page_number=${filterOptions.currentPage}&page_size=${
          filterOptions.page_size
        }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
          filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
        }${filterOptions.searchKeyword}`
      )
      .then(res => {
        dispatch(listOrgMngSuccess(res.data, filterOptions));
      })
      .catch(err => {
        dispatch(listOrgMngFail(err));
      });
  };
};

export const listOrgMngExport = filterOptions => {
  console.log("OrgListLoad Export - Action Start");
  console.dir(filterOptions);
  return dispatch => {
    dispatch(listOrgMngExportLoading());
    service
      .get(
        `/organization?page_number=${filterOptions.currentPage}&page_size=${
          filterOptions.page_size
        }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
          filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
        }${filterOptions.searchKeyword}`
      )
      .then(res => {
        console.dir(res.data);
        dispatch(listOrgMngExportSuccess(res.data));
      })
      .catch(err => {
        dispatch(listOrgMngFail(err));
      });
  };
};

export const addOrg = newOrg => {
  return dispatch => {
    dispatch(addOrgMngLoading());
    service
      .post(`/organization`, newOrg)
      .then(res => {
        dispatch(addOrgMngSuccess(res.data));
      })
      .catch(err => {
        dispatch(listOrgMngFail(err));
      });
  };
};

export const updateOrg = (org_id, newOrg) => {
  return dispatch => {
    dispatch(addOrgMngLoading());
    console.log("OrgAdd - Action Start");
    console.dir(newOrg);
    console.log(`/organization/${org_id}`);
    service
      .put(`/organization/${org_id}`, newOrg)
      .then(res => {
        dispatch(addOrgMngSuccess(res.data));
      })
      .catch(err => {
        dispatch(listOrgMngFail(err));
      });
  };
};

//----------------------------------------------------------
export const listOrgSuccess = List => {
  return {
    type: actionTypes.LIST_ORG_SUCCESS,
    orgList: List,
    loading: false
  };
};

export const listOrgFail = error => {
  return {
    type: actionTypes.LIST_ORG_FAIL,
    error: error,
    loading: false
  };
};

export const listOrgLoading = () => {
  return {
    type: actionTypes.LIST_ORG_LOADING,
    loading: true
  };
};

export const orgListLoad = () => {
  return dispatch => {
    dispatch(listOrgLoading());
    service
      .get("/organization", {})
      .then(res => {
        dispatch(listOrgSuccess(res.data));
      })
      .catch(err => {
        dispatch(listOrgFail(err));
      });
  };
};

//-----------------------------------------------------------------
