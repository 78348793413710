import axios from "axios";
import config from "../config";
import interceptor from "../helpers/Interceptor";

const templateService = axios.create({
  baseURL: config("service").template
});

interceptor(templateService);

export default templateService;
