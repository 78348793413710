import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  error: null,
  loading: false,
  u_type: { value: "ERLAdmin", label: "ERLAdmin" }
};

const accessReportStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const accessReportSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    reportResult: action.reportResult,
    u_type: action.u_type
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCESS_REPORT_SUCCESS:
      return accessReportSuccess(state, action);
    case actionTypes.ACCESS_REPORT_START:
      return accessReportStart(state, action);
    default:
      return state;
  }
};

export default reducer;
