import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import InfoReducer from "../../helpers/infoReducer";
const availableProdcutsReducer = InfoReducer("productLimitToVoucherList");

const initialState = {
  productLimitToVoucherList: null,
  selectedProductLimitToVoucher: null,
  error: null,
  loading: null,
  message: null
};

const selectProductLimitToVoucher = (state, action) => {
  return updateObject(state, {
    selectedProductLimitToVoucher: action.selectedProductLimitToVoucher
  });
};

const addProductLimitToVoucher = (state, action) => {
  let newProduct = {
    ProductID: action.product.ProductID,
    ProductTitle: action.product.ProductTitle
  };
  return {
    ...state,
    productLimitToVoucherList: state.productLimitToVoucherList.concat(
      newProduct
    )
  };
};

const removeProductLimitToVoucher = (state, action) => {
  let productLimitToVoucher = state.productLimitToVoucherList.filter(
    e => e.ProductID !== action.productId
  );
  return {
    ...state,
    productLimitToVoucherList: productLimitToVoucher,
    selectedProductLimitToVoucher: null
  };
};

const selectAssignRole = (state, action) => {
  console.log("Select Role Reducer " + action.selectedRoles);
  return updateObject(state, {
    selectedRole: action.selectedRoles
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRODUCT_LIMIT_TO_VOUCHER_LIST_SUCCESS:
      return availableProdcutsReducer.success(state, action);
    case actionTypes.PRODUCT_LIMIT_TO_VOUCHER_LIST_FAIL:
      return availableProdcutsReducer.fail(state, action);
    case actionTypes.PRODUCT_LIMIT_TO_VOUCHER_LIST_START:
      return availableProdcutsReducer.start(state, action);
    case actionTypes.SELECT_PRODUCT_LIMIT_TO_VOUCHER:
      return selectProductLimitToVoucher(state, action);
    case actionTypes.ADD_PRODUCT_LIMIT_TO_VOUCHER:
      return addProductLimitToVoucher(state, action);
    case actionTypes.REMOVE_PRODUCT_LIMIT_TO_VOUCHER:
      return removeProductLimitToVoucher(state, action);
    default:
      return state;
  }
};

export default reducer;
