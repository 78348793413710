import React from "react";
import moment from "moment";

const DateFormat = (props) => {
  let { format, date, children } = props;
  date = date || children;
  return <>{date && moment(date).format(format || "DD-MM-YYYY h:mm:ss a")}</>;
};

export default DateFormat;

// (props.children &&
//   new Intl.DateTimeFormat(props.format || "en-GB", {
//     ...props,
//     year: props.year || "numeric",
//     month: props.month || "short",
//     day: props.day || "2-digit",
//   }).format(new Date(props.date || props.children)))}
