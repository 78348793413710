import React from "react";
import "./Uploader";
import PropTypes from "prop-types";

const Uploader = (props) => {
  let fileReader;
  let content;

  const errorHandler = (e) => {
    switch (e.target.error.code) {
      case e.target.error.NOT_FOUND_ERR:
        alert("File not found.");
        break;
      case e.target.error.NOT_READABLE_ERR:
        alert("File is not readable.");
        break;
      case e.target.error.ABORT_ERR:
        break; // no operation
      default:
        alert("An error occurred reading this file.");
        break;
    }
  };

  const progressHandler = (e) => {
    if (e.lengthComputable) {
      const loaded = Math.round((e.loaded / e.total) * 100);
      let zeros = "";

      // Percent loaded in string
      if (loaded >= 0 && loaded < 10) {
        zeros = "00";
      } else if (loaded < 100) {
        zeros = "0";
      }

      // Display progress in 3-digits and increase bar length
      // document.getElementById("progress").textContent =
      //   zeros + loaded.toString();
      // document.getElementById("progressBar").style.width = loaded + "%";
    }
  };

  const processFile = (e) => {
    let fileDetials = {
      size: e.size,
      type: e.type,
    };
    return (e) => {
      let file = {
        ...fileDetials,
        content: e.target.result.split("base64,")[1],
      };
      if (props.onChange) {
        if (props.fileTypes) {
          if (props.fileTypes.indexOf(fileDetials.type) !== -1) {
            props.onChange(file);
          }
        } else {
          props.onChange(file);
        }
      }

      // console.log("content1", content);
    };
  };

  const onFileUplodaChangeHandler = (event) => {
    let selectedFile = event.target.files[0];

    fileReader = new FileReader();
    fileReader.onerror = errorHandler;
    fileReader.onprogress = progressHandler;
    fileReader.onloadend = processFile(selectedFile);
    fileReader.readAsDataURL(selectedFile);
  };

  return (
    <>
      <div className="form-group files">
        <label>{props.label}</label>
        <input
          type="file"
          className="form-control"
          multiple=""
          accept={props.fileTypes ? props.fileTypes.join(",") : ""}
          onChange={(event) => onFileUplodaChangeHandler(event)}
        />
      </div>
    </>
  );
};
export default Uploader;

Uploader.propTypes = {
  fileType: PropTypes.arrayOf(PropTypes.oneOf(["image/jpeg", "image/png"])),
  label: PropTypes.string,
};
