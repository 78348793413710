import React from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";

const CsvUploader = (props) => {
  const importCSV = (event) => {
    let File = event.target.files[0];
    if (File) {
      if (File.type === "text/csv") {
        Papa.parse(File, {
          complete: updateData,
          header: props.header || true,
          delimiter: props.delimiter || ",",
        });
      } else {
        props.onChange({ statue: 0, message: "Please select CSV file" });
      }
    } else {
      props.onChange({ statue: 0, message: "" });
    }
  };

  const updateData = (result) => {
    var data = [];
    result.data.forEach((e) => {
      e.promo !== "" && data.push(e.promo);
    });
    props.onChange({
      statue: 1,
      message: "",
      data,
    });
  };

  return (
    <>
      <input className={props.className} type="file" onChange={importCSV} />
    </>
  );
};

export default CsvUploader;

CsvUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  header: PropTypes.bool,
  delimiter: PropTypes.string,
};
