import service from "../../services/templateDesign.service";
import infoActions from "../../helpers/infoActions";
const ListActions = infoActions("FIND_TEMPLATE");
const TemplateByIdActions = infoActions("FIND_BY_ID_TEMPLATE");
const AssignedActions = infoActions("FIND_BY_Assign_TEMPLATE");
const InfoActions = infoActions("ADD_TEMPLATE");
const RenderActions = infoActions("RENDER_TEMPLATE");

export const saveTemplate = p => {
  let url = `/template/${p.Id}`;
  return dispatch => {
    dispatch(InfoActions.start());
    service
      .post(url, {
        Body: p.Body,
        Title: p.Title,
        Type: p.Type,
        Design: p.Design
      })
      .then(res => {
        dispatch(InfoActions.success(res.data));
      })
      .catch(err => {
        dispatch(InfoActions.fail(err));
      });
  };
};

export const findTemplateByDesign = Design => {
  return dispatch => {
    dispatch(ListActions.start());
    service
      .get(`/template/assign/${Design}`)
      .then(res => {
        console.log(res.data);
        dispatch(ListActions.success(res.data));
      })
      .catch(err => {
        dispatch(ListActions.fail(err));
      });
  };
};

export const findTemplateById = templateId => {
  return dispatch => {
    dispatch(TemplateByIdActions.start());
    service
      .get(`/template/${templateId}`)
      .then(res => {
        console.log(res.data);
        dispatch(TemplateByIdActions.success(res.data));
      })
      .catch(err => {
        dispatch(TemplateByIdActions.fail(err));
      });
  };
};

export const findAssignTemplate = Id => {
  return dispatch => {
    dispatch(AssignedActions.start());
    service
      .get(`/template/${Id}`)
      .then(res => {
        console.log(res.data);
        dispatch(AssignedActions.success(res.data));
      })
      .catch(err => {
        dispatch(AssignedActions.fail(err));
      });
  };
};

export const renderTemplate = (type, design, data, title) => {
  return dispatch => {
    dispatch(RenderActions.start());
    service
      .post(`/template/render/${type}/${design}`, { data, title })
      .then(res => {
        dispatch(RenderActions.success(res.data));
      })
      .catch(err => {
        dispatch(RenderActions.fail(err));
      });
  };
};
