import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  addStatus: null,
  loading: false,
  message: ""
};

const addUserSuccess = (state, action) => {
  return updateObject(state, {
    addStatus: action.addStatus
  });
};

const updateUserSuccess = (state, action) => {
  return updateObject(state, {
    addStatus: action.addStatus,
    message: action.message
  });
};
const controlOnChange = (state, action) => {
  return updateObject(state, {
    form: action.newForm
    // alertMessage: action.alertMessage,
    // alertStatus: action.alertStatus
  });
};

const addUserFail = (state, action) => {
  return updateObject(state, {
    // alertMessage: action.alertMessage,
    // alertStatus: action.alertStatus
  });
};

const loginResetStatus = (state, action) => {
  return updateObject(state, {
    addStatus: null,
    message: ""
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_USERS_SUCCESS:
      return addUserSuccess(state, action);
    case actionTypes.UPDATE_USERS_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.ADD_USERS_FAIL:
      return addUserFail(state, action);
    case actionTypes.LOGIN_RESET_STATUS:
      return loginResetStatus(state, action);
    case actionTypes.ADD_USERS_CHANGE_FORM:
      return controlOnChange(state, action);
    default:
      return state;
  }
};

export default reducer;
