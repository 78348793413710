import service from "../../services/partnerManagement.service";
import * as actionTypes from "./actionTypes";

const partnerUploadAttachmentStart = () => {
  return {
    type: actionTypes.PARTNER_UPLOUAD_ATTACHMENT_START,
  };
};

const partnerUploadAttachmentSuccess = (data) => {
  return {
    type: actionTypes.PARTNER_UPLOUAD_ATTACHMENT_SUCCESS,
    data: data,
  };
};

const partnerUploadAttachmentFail = (err) => {
  return {
    type: actionTypes.PARTNER_UPLOUAD_ATTACHMENT_FAIL,
    err: err,
  };
};

const deletePartnerUploadAttachmentStart = () => {
  return {
    type: actionTypes.DELETE_PARTNER_APP_ATTACHMENT_START,
  };
};

const deletePartnerUploadAttachmentSuccess = (data) => {
  return {
    type: actionTypes.DELETE_PARTNER_APP_ATTACHMENT_SUCCESS,
    data: data,
  };
};

const findPartnerUploadAttachmentFail = (err) => {
  return {
    type: actionTypes.FIND_PARTNER_APP_ATTACHMENT_FAIL,
    err: err,
  };
};

const findPartnerUploadAttachmentStart = () => {
  return {
    type: actionTypes.FIND_PARTNER_APP_ATTACHMENT_START,
  };
};

const findPartnerUploadAttachmentSuccess = (data) => {
  return {
    type: actionTypes.FIND_PARTNER_APP_ATTACHMENT_SUCCESS,
    data: data,
  };
};

const deletePartnerUploadAttachmentFail = (err) => {
  return {
    type: actionTypes.DELETE_PARTNER_APP_ATTACHMENT_FAIL,
    err: err,
  };
};

export const uploadAttcahmnet = (u_id, params) => {
  return (dispatch) => {
    dispatch(partnerUploadAttachmentStart());
    service
      .post(`partner/application/${u_id}/uploadAttachment`, params)
      .then((res) => {
        if (res) {
          dispatch(partnerUploadAttachmentSuccess(res.data));
        } else {
          dispatch(partnerUploadAttachmentFail("Error"));
        }
      })
      .catch((err) => {
        dispatch(partnerUploadAttachmentFail(err));
      });
  };
};

export const findApplicationAttachmnetsById = (id) => {
  return (dispatch) => {
    dispatch(findPartnerUploadAttachmentStart());
    service
      .get(`partner/application/attachment/${id}`)
      .then((res) => {
        if (res) {
          dispatch(findPartnerUploadAttachmentSuccess(res.data));
        } else {
          dispatch(findPartnerUploadAttachmentFail("Error"));
        }
      })
      .catch((err) => {
        dispatch(findPartnerUploadAttachmentFail(err));
      });
  };
};

export const deleteUploadAttcahmnet = (id) => {
  return (dispatch) => {
    dispatch(deletePartnerUploadAttachmentStart());
    service
      .delete(`partner/application/attachment/${id}`)
      .then((res) => {
        if (res) {
          dispatch(deletePartnerUploadAttachmentSuccess(res.data));
        } else {
          dispatch(deletePartnerUploadAttachmentFail("Error"));
        }
      })
      .catch((err) => {
        dispatch(deletePartnerUploadAttachmentFail(err));
      });
  };
};
