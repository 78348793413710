import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import helper from "../../helpers/listReducer";
const Helper = helper();

const initialState = {
  ...Helper.initState(),
  sort_by: "BatchID",
  sort_dir: "desc",
  ShowMode: "BatchList",
};

const listSuccess = (state, action) => {
  return updateObject(state, {
    ...Helper.successState(action),
  });
};

const listFail = (state, action) => {
  return updateObject(state, {
    ...Helper.failState(action),
  });
};

const voucherBatchShowModeChange = (state, action) => {
  return updateObject(state, {
    ShowMode: action.mode,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_VOUCHER_BATCH_SUCCESS:
      return listSuccess(state, action);
    case actionTypes.LIST_VOUCHER_BATCH_FAIL:
      return listFail(state, action);
    case actionTypes.LIST_VOUCHER_BATCH_START:
      return Helper.start(state, action);
    case actionTypes.LIST_VOUCHER_BATCH_SELECT:
      return Helper.select(state, action);
    case actionTypes.LIST_VOUCHER_BATCH_EXPORT_START:
      return Helper.exportStart(state, action);
    case actionTypes.LIST_VOUCHER_BATCH_EXPORT_SUCCESS:
      return Helper.exportSuccess(state, action);
    case actionTypes.VOUCHER_BATCH_CHANGE_SHOW_MODE:
      return voucherBatchShowModeChange(state, action);
    default:
      return state;
  }
};

export default reducer;
