import React from "react";
//var classNames = require('classnames');

const celLink = props => {
  return (
    <td>
      <div>{props.children}</div>
    </td>
  );
};

export default celLink;
