import service from "../../services/voucher.service";
import infoActions from "../../helpers/infoActions";
import * as actionTypes from "./actionTypes";
const AvailableProductsActions = infoActions("AVAILABLE_VOUCHER_PRODUCTS");
const GetProductActions = infoActions("VOUCHER_PRODUCTS_LIST");
const submitVoucherProductActions = infoActions("SUBMIT_VOUCHER_PRODUCTS");

export const selectVoucherProduct = productId => {
  return {
    type: actionTypes.SELECTE_VOUCHER_PRODUCT,
    selectedVoucherProduct: productId
  };
};

export const addVoucherProduct = product => {
  return {
    type: actionTypes.ADD_VOUCHER_PRODUCT,
    product: product
  };
};

export const removeVoucherProduct = productId => {
  return {
    type: actionTypes.REMOVE_VOUCHER_PRODUCT,
    productId: productId
  };
};

export const getVoucherProducts = voucherId => {
  return dispatch => {
    dispatch(GetProductActions.start());
    service
      .get(`voucher/${voucherId}/products`)
      .then(res => {
        dispatch(GetProductActions.success(res.data));
      })
      .catch(err => {
        dispatch(GetProductActions.fail(err));
      });
  };
};

export const getVoucherProductsExclude = voucherId => {
  return dispatch => {
    dispatch(GetProductActions.start());
    service
      .get(`voucher/${voucherId}/products/exclude`)
      .then(res => {
        dispatch(GetProductActions.success(res.data));
      })
      .catch(err => {
        dispatch(GetProductActions.fail(err));
      });
  };
};

export const submitVoucherProduct = (voucherId, productIDs) => {
  return dispatch => {
    dispatch(submitVoucherProductActions.start());
    service
      .post(`voucher/${voucherId}/products`, { ProductIDs: productIDs })
      .then(res => {
        dispatch(submitVoucherProductActions.success(res.data));
      })
      .catch(err => {
        dispatch(submitVoucherProductActions.fail(err));
      });
  };
};
