import React from "react";
import Tag from "../../Tag";
import { NavLink, Link } from "react-router-dom";
import classNames from "classnames";
import { isMainMenuSelected } from "../../../helpers/utility";
import _ from "lodash";

const Navbar = props => {
  const menuItemsAsPessmision = props.menuItems.filter(
    item => _.intersection(item.permissions, props.persmissions).length > 0
  );

  let menuItems = menuItemsAsPessmision.map((item, index) => {
    return (
      <li
        key={index}
        className={classNames({
          "menu-item": true,
          active: isMainMenuSelected(item)
        })}
      >
        <NavLink
          key={"link-" + index}
          className="nav-link"
          to={item.path}
          onClick={() => props.onItemSelectHandler(item.title)}
        >
          {item.title}
        </NavLink>
      </li>
    );
  });
  return (
    <Tag
      tagName="nav"
      className="fixed-navbar navbar navbar-expand-lg navbar-dark bg-dark"
    >
      <div className="navbar-brand col-md-2 col-sm-3">Admin Portal</div>
      <div className="width-100" id="navbarNav">
        <ul className="">
          {menuItems}

          {/* <li className="menu-item float-right text-nowrap">
            <NavLink className="nav-link" to="/logout">
              {props.userInfo ? props.userInfo.fullName : ""}
            </NavLink>
          </li> */}

          <div
            className="collapse navbar-collapse dropdown-div"
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {props.userInfo ? props.userInfo.fullName : ""}
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item" href="/profile">
                    Profile
                  </a>
                  <a className="dropdown-item" href="/logout">
                    Sign out
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </Tag>
  );
};

export default Navbar;
