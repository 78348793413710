import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
var HtmlToReactParser = require("html-to-react").Parser;

const PrintPreview = (props) => {
  let printData;
  let disablePrint = false;
  const readPrintData = () => {
    printData = JSON.parse(localStorage.getItem("printData"));
    console.log({ printData });
    if (printData != null) {
      localStorage.removeItem("printData");
      let htmlContent = "";
      let pageBreak = "<span className='newPagePrint red'> </span> <br/>";
      printData.forEach((item) => {
        htmlContent = htmlContent + item + pageBreak;
      });
      htmlContent = htmlContent.substring(
        0,
        htmlContent.length - pageBreak.length,
      );
      printData = htmlContent;
    } else {
      printData = "<h1>Data not found.</h1>";
      disablePrint = true;
    }
    let htmlToReactParser = new HtmlToReactParser();
    printData = htmlToReactParser.parse(printData);
  };

  const onPrintHandler = () => {
    var content = document.getElementById("divContents");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(
      '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" >',
    );
    pri.document.title = "";
    pri.document.write(
      "  <style> .w-100{width:100%} .header, .header-space, .footer, .footer-space { height: 50px; } " +
        " .header { position: fixed; top: 0; } .footer { position: fixed; bottom: 0; }   page{margin: 0mm } .newPagePrint {display: block; page-break-before: always;}  </style> ",
    );
    pri.document.write(content.innerHTML);
    setTimeout(() => {
      pri.document.close();
      pri.focus();
      pri.print();
    }, 250);
  };
  return (
    <>
      {readPrintData()}
      <Button
        disabled={disablePrint}
        className="btn btn-success m-3 float-right"
        label="Print"
        onClick={onPrintHandler}
      />

      <iframe
        className="d-none d-print-block embed-responsive-item"
        id="ifmcontentstoprint"
      />

      <div id="divContents" className="wrapper">
        <div class="container">
          <table className="w-100">
            <thead>
              <tr>
                <td>
                  <div className="header-space">&nbsp;</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className="content"> {printData} </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <div className="footer-space">&nbsp;</div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="header" />
          <div class="footer" />
        </div>
      </div>
    </>
  );
};
export default PrintPreview;

PrintPreview.propTypes = {};
