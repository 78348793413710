import React, { Component } from "react";

class Tag extends Component {
  render() {
    const TagName = this.props.tagName || "div";
    const { tagName, ...rest } = this.props;
    return <TagName {...rest} />;
  }
}

export default Tag;
