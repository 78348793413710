import * as actionTypes from "../store/actions/actionTypes";

const infoActions = prefix => {
  const start = () => {
    return {
      type: actionTypes[`${prefix}_START`]
    };
  };

  const success = info => {
    return {
      type: actionTypes[`${prefix}_SUCCESS`],
      info: info
    };
  };

  const fail = error => {
    return {
      type: actionTypes[`${prefix}_FAIL`],
      error: error
    };
  };

  return {
    start,
    success,
    fail
  };
};

export default infoActions;
