import service from "../../services/booking.service";
import * as actionTypes from "./actionTypes";
import infoActions from "../../helpers/infoActions";
const addActions = infoActions("ADD_TRAIN_STATUS_DETAIL");
const editActions = infoActions("EDIT_LOYALTY");

export const addTrainStatus = params => {
  return dispatch => {
    // dispatch(addTrainStatusStart());
    dispatch(addActions.start());
    service
      .put(`/sales/backoffice/addtrainstatusdetail`, params)
      .then(res => {
        setTimeout(() => {
            dispatch(addActions.success(res.data));
        }, 2000);
      })
      .catch(err => {
        dispatch(addActions.fail(err.message));
      });
  };
};
