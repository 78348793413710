import React from "react";
import CelLabelHeader from "./CelLabelHeader";

const rowHeader = props => {
  var colsarr = props.cols.map(function(col, index) {
    return (
      <CelLabelHeader key={index} field={col.field} onSort={props.onSort}>
        {col.title}
      </CelLabelHeader>
    );
  }, this);

  return <tr>{colsarr}</tr>;
};

export default rowHeader;
