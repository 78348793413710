import axios from "axios";
import config from "../config";
import interceptor from "../helpers/Interceptor";

const voucherService = axios.create({
  baseURL: config("service").voucher
});

interceptor(voucherService);

export default voucherService;
