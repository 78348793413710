import React from "react";
import classNames from "classnames";
import ListItemCheckbox from "./ListItemCheckbox";
const List = props => {
  const isData = n => {
    return (
      props.data !== null && props.data !== undefined && props.data.length > n
    );
  };
  const isSelected = col => {
    if (props.multiSelect === true && props.selectedRows !== null) {
      return (
        props.selectedRows.find(item => {
          return item == col;
        }) !== undefined
      );
    } else {
      return props.selectedRows === col;
    }
  };
  const showCheckbox = () => {
    return props.checkbox === null || props.checkbox === undefined
      ? false
      : props.checkbox;
  };

  const showSelectedBackground = () => {
    return props.highlight === null || props.highlight === undefined
      ? false
      : props.highlight;
  };
  const onRowSelectHandler = (event, key) => {
    if (isSelected(key) && props.multiSelect === false) {
      //deSelect
      props.onSelect(null);
    } else {
      props.onSelect(key);
    }
  };
  const rows = () => {
    return props.data.map((row, index) => {
      return (
        <>
          <button
            type="button"
            key={props.data[index][props.keyField]}
            className={classNames({
              "list-group-item": true,
              "list-group-item-action": true,
              active:
                isSelected(props.data[index][props.keyField]) &&
                showSelectedBackground()
            })}
            onClick={event =>
              onRowSelectHandler(event, props.data[index][props.keyField])
            }
          >
            <div className="input-group">
              {showCheckbox() && (
                <ListItemCheckbox
                  value={isSelected(props.data[index][props.keyField])}
                  whiteSelected={props.whiteSelected}
                />
              )}
              <div className="input-group-append">
                <span id="basic-addon2">
                  {props.data[index][props.fieldName]}
                </span>
              </div>
            </div>
          </button>
        </>
      );
    });
  };
  return (
    <>
      {console.log(props.selectedRows)}
      <div className="list-group">{isData(0) && rows()}</div>
    </>
  );
};
export default List;
