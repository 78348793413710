import * as actionTypes from "../actions/actionTypes";

const initialState = {
  controls: null,
  selectedFare: null,
  stations: null,
};

const addBackOfficeParams = (state, action) => {
  return { ...state, controls: action.state };
};

const addBackOfficePaymentParams = (state, action) => {
  return { ...state, selectedFare: action.state };
};

const addBackOfficeAllStations = (state, action) => {
  return { ...state, stations: action.state };
};
const setBackOfficeGenerateDone = (state, action) => {
  return { ...state, selectedFare: action.state };
};

const cleanBackofficeState = () => {
  return { ...initialState };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_BACK_OFFICE_PARAMS:
      return addBackOfficeParams(state, action);
    case actionTypes.ADD_BACK_OFFICE_PAYMENT_PARAMS:
      return addBackOfficePaymentParams(state, action);
    case actionTypes.ADD_BACK_OFFICE_ALL_STATIONS:
      return addBackOfficeAllStations(state, action);
    case actionTypes.SET_BACK_OFFICE_GENEREATE_DONE:
      return setBackOfficeGenerateDone(state, action);
    case actionTypes.CLEAN_BACK_OFFICE_STATE:
      return cleanBackofficeState(state, action);
    default:
      return state;
  }
};

export default reducer;
