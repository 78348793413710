import "./Spinner.css";
import React from "react";

class spinner extends React.Component {
  constructor(props) {
    super(props);
    this.enableMessage = this.enableMessage.bind(this);

    this.state = {
      displayMessage: false
    };

    this.timer = setTimeout(this.enableMessage, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage() {
    this.setState({ displayMessage: true });
  }

  render() {
    const { displayMessage } = this.state;

    if (!displayMessage) {
      return null;
    }

    return (
      <div className="Fill">
        <div className="d-flex justify-content-center Backdrop pt-5">
          <div className="spinner-border m-auto" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }
}

export default spinner;
