import React, { Component } from "react";
// import QrReader from "react-qr-reader";

import { Button, Col, Row } from "./index";

// Use React.lazy to lazily load the component
const QrReader = React.lazy(() => import('react-qr-reader'));

class QrCodeReader extends Component {
  state = {
    result: "No result",
  };

  handleScan = (data) => {
    if (data) {
      this.setState(
        {
          result: data,
        },
        () => {
          this.props.onCaptureBarcode(data);
        }
      );
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  render() {
    return (
      <Row>
        <Col className="col-12">
          <h3>Please scan QrCode</h3>
        </Col>
        <Col className="col-12">
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "100%" }}
          />
        </Col>
        <Col className="col-12 text-center mt-5 ">
          <Button
            name="btnCancel"
            type="button"
            label="Cancel"
            className="btn btn-danger w-100-px "
            onClick={() => this.props.onCancel()}
          />
        </Col>
      </Row>
    );
  }
}

export default QrCodeReader;
