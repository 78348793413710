import service from "../../services/voucher.service";
import infoActions from "../../helpers/infoActions";
import * as actionTypes from "./actionTypes";
const addActions = infoActions("ADD_VOUCHER_BATCH");
//const editActions = infoActions("EDIT_VOUCHER");

export const changeShowMode = () => {
  return {
    type: actionTypes.VOUCHER_BATCH_CHANGE_SHOW_MODE,
    mode: "BatchList"
  };
};

export const addBatchVoucher = (voucherID, params) => {
  return dispatch => {
    dispatch(addActions.start());
    service
      .post(`/voucher/${voucherID}/batchcode`, params)
      .then(res => {
        dispatch(addActions.success(res.deta));
        dispatch(changeShowMode());
      })
      .catch(err => {
        dispatch(addActions.fail(err.message));
      });
  };
};

// export const updateBatchVoucher = (voucherId, params) => {
//
//   return dispatch => {
//     dispatch(editActions.start());
//     service
//       .put(`/Voucher/${voucherId}`, params)
//       .then(res => {
//         dispatch(editActions.success(res.data));
//       })
//       .catch(err => {
//         dispatch(editActions.fail(err.message));
//       });
//   };
// };
