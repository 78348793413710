import * as actionTypes from "../actions/actionTypes";
import {
  updateObject,
  permissionGroupBy,
  updateMultiSelect
} from "../../helpers/utility";

const initialState = {
  partnerApplicationAttachmentList: [],
  error: null,
  loading: false,
  sort_by: "p_title",
  sort_dir: "asc",
  searchKeyword: "",
  selectedAttachmentRow: null,
  currentPage: 1,
  total: 0,
  errorMessage: null,
  newRow: null,
  deleteRow: null,
  file: null
};

const partnerUploadAttachmentStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const partnerUploadAttachmentSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    newRow: action.data,
    errorMessage: ""
  });
};

const partnerUploadAttachmentFail = (state, action) => {
  return updateObject(state, {
    err: action.err,
    errorMessage: action.err,
    loading: false
  });
};

const deletepartnerUploadAttachmentStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const deletePartnerUploadAttachmentSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    deleteRow: action.data,
    errorMessage: ""
  });
};

const deletePartnerUploadAttachmentFail = (state, action) => {
  return updateObject(state, {
    err: action.err,
    errorMessage: action.err,
    loading: false
  });
};

const findpartnerUploadAttachmentStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const findPartnerUploadAttachmentSuccess = (state, action) => {
  console.log(
    "findPartnerUploadAttachmentSuccess",
    JSON.stringify(action.data)
  );
  return updateObject(state, {
    loading: false,
    file: action.data,
    errorMessage: ""
  });
};

const findPartnerUploadAttachmentFail = (state, action) => {
  return updateObject(state, {
    err: action.err,
    errorMessage: action.err,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PARTNER_UPLOUAD_ATTACHMENT_START:
      return partnerUploadAttachmentStart(state, action);
    case actionTypes.PARTNER_UPLOUAD_ATTACHMENT_SUCCESS:
      return partnerUploadAttachmentSuccess(state, action);
    case actionTypes.PARTNER_UPLOUAD_ATTACHMENT_FAIL:
      return partnerUploadAttachmentFail(state, action);
    case actionTypes.DELETE_PARTNER_APP_ATTACHMENT_START:
      return deletepartnerUploadAttachmentStart(state, action);
    case actionTypes.DELETE_PARTNER_APP_ATTACHMENT_SUCCESS:
      return deletePartnerUploadAttachmentSuccess(state, action);
    case actionTypes.DELETE_PARTNER_APP_ATTACHMENT_FAIL:
      return deletePartnerUploadAttachmentFail(state, action);
    case actionTypes.FIND_PARTNER_APP_ATTACHMENT_START:
      return findpartnerUploadAttachmentStart(state, action);
    case actionTypes.FIND_PARTNER_APP_ATTACHMENT_SUCCESS:
      return findPartnerUploadAttachmentSuccess(state, action);
    case actionTypes.FIND_PARTNER_APP_ATTACHMENT_FAIL:
      return findPartnerUploadAttachmentFail(state, action);
    default:
      return state;
  }
};

export default reducer;
