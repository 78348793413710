import React from "react";
import PropTypes from "prop-types";
import Textbox from "../Textbox";

const CellTextbox = props => {
  return (
    <td
      className="cell-text-unwrap"
      style={props.width && { width: props.width }}
    >
      <Textbox
        id={props.id}
        type={props.format}
        value={props.value}
        onChange={props.onChange}
        prepend={props.prepend}
        append={props.append}
        className="form-control"
      />
    </td>
  );
};

export default CellTextbox;

CellTextbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["text", "number", "date"]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onBlur: PropTypes.func,
  prepend: PropTypes.string,
  append: PropTypes.string
};
