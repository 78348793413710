import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import InfoReducer from "../../helpers/infoReducer";
const voucherProductsListReducer = InfoReducer("voucherProductsList");
const submitVoucherProductsReducer = InfoReducer("voucherProductsResult");

const initialState = {
  voucherProductsList: null,
  selectedVoucherProduct: null,
  voucherProductsResult: null
};

const onSelecteVoucherProduct = (state, action) => {
  return updateObject(state, {
    selectedVoucherProduct: action.selectedVoucherProduct
  });
};

const addProduct = (state, action) => {
  let newProduct = {
    ProductTitle: action.product.label,
    ProductID: action.product.value
  };
  return {
    ...state,
    voucherProductsList: state.voucherProductsList.concat(newProduct)
  };
};

const removeProduct = (state, action) => {
  let products = state.voucherProductsList.filter(
    e => e.ProductID !== action.productId
  );
  return {
    ...state,
    voucherProductsList: products,
    selectedVoucherProduct: null
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VOUCHER_PRODUCTS_LIST_SUCCESS:
      return voucherProductsListReducer.success(state, action);
    case actionTypes.VOUCHER_PRODUCTS_LIST_FAIL:
      return voucherProductsListReducer.fail(state, action);
    case actionTypes.VOUCHER_PRODUCTS_LIST_START:
      return voucherProductsListReducer.start(state, action);

    case actionTypes.SUBMIT_VOUCHER_PRODUCTS_SUCCESS:
      return submitVoucherProductsReducer.success(state, action);
    case actionTypes.SUBMIT_VOUCHER_PRODUCTS_FAIL:
      return submitVoucherProductsReducer.fail(state, action);
    case actionTypes.SUBMIT_VOUCHER_PRODUCTS_START:
      return submitVoucherProductsReducer.start(state, action);

    case actionTypes.SELECTE_VOUCHER_PRODUCT:
      return onSelecteVoucherProduct(state, action);
    case actionTypes.ADD_VOUCHER_PRODUCT:
      return addProduct(state, action);
    case actionTypes.REMOVE_VOUCHER_PRODUCT:
      return removeProduct(state, action);
    default:
      return state;
  }
};

export default reducer;
