import React from "react";
import { Link } from "react-router-dom";
//var classNames = require('classnames');

const celAction = props => {
  return (
    <td>
      <div>
        <Link to={props.actionUrl.replace(":id", props.id)}>{props.name}</Link>
      </div>
    </td>
  );
};

export default celAction;
