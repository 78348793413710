import service from "../../services/voucher.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_VOUCHER_BATCH");

export const batchChangeShowMode = mode => {
  return {
    type: actionTypes.VOUCHER_BATCH_CHANGE_SHOW_MODE,
    mode: mode
  };
};

export const selectVoucherBatch = row => {
  return {
    type: actionTypes.LIST_VOUCHER_BATCH_SELECT,
    selectedRow: row
  };
};

export const listVoucherBatches = (filterOptions, isExport = false) => {
  return dispatch => {
    let url = `/voucher/${filterOptions.VoucherID}/batchcode?page_number=${
      filterOptions.currentPage
    }&page_size=${filterOptions.page_size}
    &sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
      filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
    }${filterOptions.searchKeyword}`;
    dispatch(ListActions.start(isExport));
    service
      .get(url)
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
