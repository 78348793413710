import React from "react";
import classNames from "classnames";

const Tab = props => {
  const tabs = props.tabs.map(tab => {
    return (
      <li className="nav-item" key={tab.id}>
        <a
          className={classNames({ "nav-link": true, active: tab.active })}
          onClick={event => props.onClick(event, tab.id)}
        >
          {tab.title}
        </a>
      </li>
    );
  });
  return (
    <>
      <ul className="nav nav-tabs">{tabs}</ul>
    </>
  );
};
export default Tab;
