import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  token: null,
  userId: null,
  error: null,
  loading: false,
  userInfo: {},
  authRedirectPath: "/",
  loginStatus: {},
  mfaSessionKey: false,
  isExpired: false,
  forgotPassword: false,
  resetForgotPassword: false,
  forgotPasworSuccessMessage: "",
  forgotPasworFaileMessage: "",
  isTokenValid: false,
  resetPaswordMessage: "",
};

const loginStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const forgotPasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const forgotPasswordCleanMessage = (state, action) => {
  return updateObject(state, {
    forgotPasworSuccessMessage: "",
    forgotPasworFaileMessage: "",
    loading: false,
  });
};

const forgotPasswordSuccess = (state, action) => {
  return updateObject(state, {
    forgotPasworSuccessMessage: "Please check inbox",
    loading: false,
  });
};

const forgotPasswordFail = (state, action) => {
  return updateObject(state, {
    // forgotPasworFaileMessage: "",
    loading: false,
  });
};

const CheckTokenForgotPasswordPasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const CheckTokenForgotPasswordPasswordSuccess = (state, action) => {
  return updateObject(state, {
    // forgotPasworSuccessMessage: "",
    resetForgotPassword: true,
    isTokenValid: action.isValid,
    resetPaswordMessage: action.isValid ? "" : "Token incorrect",
    loading: false,
  });
};

const CheckTokenforgotPasswordFail = (state, action) => {
  return updateObject(state, {
    forgotPasworFaileMessage: "",
    resetForgotPassword: false,
    loading: false,
  });
};

const forgotPasswordRestStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const forgotPasswordRestSuccess = (state, action) => {
  return updateObject(state, {
    resetPaswordMessage: "Password has been chaned successfully.",
    loading: false,
  });
};

const forgotPasswordRestFail = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const loginChangePasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const loginChangePasswordSuccess = (state, action) => {
  return updateObject(state, {
    resetPaswordMessage: "Password has been chaned successfully.",
    loading: false,
  });
};

const loginChangePasswordFail = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const authSuccess = (state, action) => {
 
  return updateObject(state, {
    token: action.accessToken,
    userInfo: action.userInfo,
    loading: false,
  });
};

const setloginMfaStatus = (state, action) => {
  return updateObject(state, {
    mfaSessionKey: action.mfaSessionKey,
    loginStatus: action.errorDate,
    loading: false,
  });
};

const setloginLockedStatus = (state, action) => {
  return updateObject(state, {
    loginStatus: action.errorDate,
    userInfo: action.userInfo,
    loading: false,
  });
};

const setloginExpiredStatus = (state, action) => {
  return updateObject(state, {
    userInfo: action.userInfo,
    isExpired: true,
    loading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    loginStatus: action.errorDate,
    loading: false,
  });
};

const resetPasswordStatusClean = (state, action) => {
  return updateObject(state, {
    forgotPassword: false,
    isExpired: false,
    resetForgotPassword: false,
  });
};

const setloginStatus = (state, action) => {
  return updateObject(state, {
    loginStatus: action.errorDate,
    loading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    userInfo: {},
  });
};

const setForgotPassword = (state, action) => {
  return updateObject(state, { forgotPassword: action.forgotPassword });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return loginStart(state, action);
    case actionTypes.LOGIN_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.LOGIN_FAIL:
      return authFail(state, action);
    case actionTypes.LOGOUT:
      return authLogout(state, action);
    case actionTypes.LOGIN_SET_MFA_STATUS:
      return setloginMfaStatus(state, action);
    case actionTypes.LOGIN_SET_LOCKED_STATUS:
      return setloginLockedStatus(state, action);
    case actionTypes.LOGIN_SET_EXPIRED_STATUS:
      return setloginExpiredStatus(state, action);
    case actionTypes.LOGIN_SET_STATUS:
      return setloginStatus(state, action);

    case actionTypes.LOGIN_SET_FORGOTPASSWORD_STATUS:
      return setForgotPassword(state, action);
    case actionTypes.FORGOT_PASSWORD_END_EMAIL_START:
      return forgotPasswordStart(state, action);
    case actionTypes.FORGOT_PASSWORD_END_EMAIL_SUCCESS:
      return forgotPasswordSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_END_EMAIL_FAIL:
      return forgotPasswordFail(state, action);
    case actionTypes.RESET_PASSWORD_END_CLEAN_MESSAGE:
      return forgotPasswordCleanMessage(state, action);

    case actionTypes.FORGOT_PASSWORD_CHECK_TOKEN_SUCCESS:
      return CheckTokenForgotPasswordPasswordSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_CHECK_TOKEN_START:
      return CheckTokenForgotPasswordPasswordStart(state, action);
    case actionTypes.FORGOT_PASSWORD_CHECK_TOKEN_FAIL:
      return CheckTokenforgotPasswordFail(state, action);

    case actionTypes.FORGOT_PASSWORD_RESET_SUCCESS:
      return forgotPasswordRestSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_RESET_START:
      return forgotPasswordRestStart(state, action);
    case actionTypes.FORGOT_PASSWORD_RESET_FAIL:
      return forgotPasswordRestFail(state, action);

    case actionTypes.LOGIN_CHANGE_PASSWORD_SUCCESS:
      return loginChangePasswordSuccess(state, action);
    case actionTypes.LOGIN_CHANGE_PASSWORD_START:
      return loginChangePasswordStart(state, action);
    case actionTypes.LOGIN_CHANGE_PASSWORD_FAIL:
      return loginChangePasswordFail(state, action);

    case actionTypes.RESET_PASSWORD_BACK_TO_LOGIN:
      return resetPasswordStatusClean(state, action);

    default:
      return state;
  }
};

export default reducer;
