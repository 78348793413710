import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
import infoActions from "../../helpers/infoActions";
const ListActions = listActions("LIST_ROLES");
const InfoActions = infoActions("DELETE_ROLES");

export const selectRole = Role => {
  return {
    type: actionTypes.LIST_ROLES_SELECT,
    selectedRoles: Role
  };
};

export const listRoles = filterOptions => {
  var url = `/role?page_number=${
    filterOptions.currentPage
  }&page_size=10&sort_by=${filterOptions.sort_by}&sort_dir=${
    filterOptions.sort_dir
  }${filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""}${
    filterOptions.searchKeyword
  }`;
  console.log("rolesURL:", url);
  return dispatch => {
    dispatch(ListActions.start());
    service
      .get(url)
      .then(res => {
        console.log("rolesDtat:", res.data);
        dispatch(ListActions.success(res.data, filterOptions));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};

export const deleteRole = roleId => {
  return dispatch => {
    dispatch(InfoActions.start());
    service
      .delete(`/role/${roleId}`)
      .then(res => {
        dispatch(InfoActions.success(res.data));
      })
      .catch(err => {
        dispatch(InfoActions.fail(err));
      });
  };
};
