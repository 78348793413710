import service from "../../services/partnerManagement.service";
import infoActions from "../../helpers/infoActions";
//import actActions from "../../helpers/actActions";
const InfoActions = infoActions("LOAD_PARTNER_APPLICATION_DETAIL");
const InfoActionsStatus = infoActions(
  "CHANGE_STATE_PARTNER_APPLICATION_DETAIL"
);

export const loadPartnerApplicationDetail = id => {
  console.log(`/partner/application/${id}`);
  return dispatch => {
    dispatch(InfoActions.start());
    service
      .get(`/partner/application/${id}`)
      .then(res => {
        dispatch(InfoActions.success(res.data));
      })
      .catch(err => {
        dispatch(InfoActions.fail(err));
      });
  };
};

export const changePartnerApplicationStatus = (id, status) => {
  return dispatch => {
    dispatch(InfoActionsStatus.start());
    service
      .put(`/partner/application/${id}/status`, { status: status })
      .then(res => {
        dispatch(InfoActionsStatus.success(res.data));
      })
      .catch(err => {
        dispatch(InfoActionsStatus.fail(err));
      });
  };
};
