import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import helper from "../../helpers/listReducer";
const Helper = helper();

const initialState = {
  ...Helper.initState(),
  voucherType: { value: "cash", label: "Cash" },
  voucherStatus: { value: "active", label: "Active" },
  isExternal: { value: "0", label: "Internal" },
  sort_by: "VoucherTitle"
};

const listSuccess = (state, action) => {
  return updateObject(state, {
    voucherType: action.filterOptions.voucherType,
    voucherStatus: action.filterOptions.voucherStatus,
    isExternal: action.filterOptions.isExternal,
    ...Helper.successState(action)
  });
};

const listFail = (state, action) => {
  return updateObject(state, {
    voucherType: action.filterOptions.voucherType,
    voucherStatus: action.filterOptions.voucherStatus,
    isExternal: action.filterOptions.isExternal,
    ...Helper.failState(action)
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_VOUCHER_SUCCESS:
      return listSuccess(state, action);
    case actionTypes.LIST_VOUCHER_FAIL:
      return listFail(state, action);
    case actionTypes.LIST_VOUCHER_START:
      return Helper.start(state, action);
    case actionTypes.LIST_VOUCHER_SELECT:
      return Helper.select(state, action);
    case actionTypes.LIST_VOUCHER_EXPORT_START:
      return Helper.exportStart(state, action);
    case actionTypes.LIST_VOUCHER_EXPORT_SUCCESS:
      return Helper.exportSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
