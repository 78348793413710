import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { isNotNull } from "../helpers/utility";

const Textbox = props => {
  // const [state, onBulerHandler] = useState(() => {
  //   props.onBulerHandler1 && props.onBulerHandler1("test");
  //   console.log("in");
  // });

  // useEffect(() => {
  //   console.log("ef");
  // });

  const renderTextbox = () => {
    return (
      <div>
        <input
          disabled={
            isNotNull(props.disabled) ? (props.disabled ? "disabled" : "") : ""
          }
          type={props.type}
          id={props.id}
          value={props.value}
          className={classNames({
            "placeholder-shown": true,
            "form-control": true,
            "Is-Invalid": !props.valid && props.isSubmited,
            w100: props.w100 !== undefined ? props.w100 : false
          })}
          placeholder={props.placeholder}
          onBlur={e => props.onBlur && props.onBlur(e)}
          // onBlur={e => onBulerHandler(e)}
          onChange={event =>
            props.onChange !== undefined
              ? props.onChange(props.id, event.target.value)
              : null
          }
        />
      </div>
    );
  };
  return (
    <div className="form-group">
      {props.label !== null &&
      props.label !== undefined &&
      props.label !== "" ? (
        <label className="">{props.label}</label>
      ) : null}
      {isNotNull(props.append) || isNotNull(props.prepend) ? (
        <div className="input-group ">
          {isNotNull(props.prepend) && (
            <div className="input-group-prepend">
              <span className="input-group-text">{props.prepend}</span>
            </div>
          )}
          {renderTextbox()}
          {isNotNull(props.append) && (
            <div className="input-group-append">
              <span className="input-group-text">{props.append}</span>
            </div>
          )}
        </div>
      ) : (
        renderTextbox()
      )}
    </div>
  );
};

export default Textbox;
