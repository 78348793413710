import * as actionTypes from "../store/actions/actionTypes";
import { updateObject, updateMultiSelect } from "./utility";

const listReducer = () => {
  const start = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true,
      listExport: null
    });
  };

  const select = (state, action, multiSelect = false) => {
    return updateObject(state, {
      selectedRow: multiSelect
        ? updateMultiSelect(state.selectedRow, action.selectedRow)
        : action.selectedRow,
      selectedRowData: action.selectedRowData
    });
  };

  const exportStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loadingExport: true,
      listExport: null
    });
  };

  const exportSuccess = (state, action) => {
    return updateObject(state, {
      listExport: action.list,
      loadingExport: false,
      error: null
    });
  };

  const successState = (action, state = null) => {
    return {
      listData: action.list,
      loading: false,
      sort_by: action.filterOptions.sort_by,
      sort_dir: action.filterOptions.sort_dir,
      searchKeyword: action.filterOptions.searchKeyword,
      selectedRow:
        state !== null
          ? action.filterOptions.currentPage === state.currentPage
            ? null
            : state.selectedRow
          : null,
      selectedRowData: null,
      currentPage: action.filterOptions.currentPage,
      total: action.total,
      error: null,
      loadingExport: false,
      listExport: null
    };
  };

  const initState = () => {
    return {
      listData: null,
      listExport: null,
      error: null,
      loading: false,
      loadingExport: false,
      sort_by: "",
      sort_dir: "asc",
      searchKeyword: "",
      selectedRow: null,
      selectedRowData: null,
      currentPage: 1,
      total: 0
    };
  };

  const failState = action => {
    return {
      loading: false,
      sort_by: action.filterOptions.sort_by,
      sort_dir: action.filterOptions.sort_dir,
      searchKeyword: action.filterOptions.searchKeyword,
      currentPage: action.filterOptions.currentPage,
      listExport: null,
      error: action.error.response
        ? action.error.response.data.error
        : action.error
    };
  };

  return {
    start,
    select,
    exportStart,
    exportSuccess,
    successState,
    initState,
    failState
  };
};

export default listReducer;
