import React from "react";
import { Link } from "react-router-dom";
import CelLabel from "./CelLabel";
import CelButton from "./CelButton";
import Permission from "../../hoc/Permission";

const CelProcess = (props) => {
  const loadControl = ({ col, index, onSelectHandler, colsData, status }) => {
    return status && status === true ? (
      <CelLabel
        key={index}
        format={col.format}
        value={col.lblTitle + " " + (col.field && colsData[col.btnFieldTitle])}
        width={col.width}
      />
    ) : (
      <CelButton
        action={col.action}
        id={colsData[props.keyField]}
        name={col.field && colsData[col.btnFieldTitle]}
        key={index}
        className={col.className}
        onClick={onSelectHandler}
        disabled={
          col.disabledField !== undefined && col.disabledField !== null
            ? colsData[col.disabledField]
            : false
        }
      />
    );
  };

  return <>{loadControl(props)}</>;
};

export default CelProcess;
