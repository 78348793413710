import React, { useState } from "react";
import Icon from "./Icon";

const IconButton = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const iconButtonStyle = {
    position: "relative",
    display: "inline-block",
  };

  const tooltipStyle = {
    width:'96px',
    textAlign:"center",
    zIndex:'100',
    position: "absolute",
    top: "-35px",
    left: "50%",
    transform: "translateX(-50%)",
    background: "rgba(0, 0, 0, 0.7)",
    color: "white",
    padding: "5px",
    borderRadius: "8px",
    display: showTooltip ? "block" : "none",

  };

  const linkStyle = {
    cursor: "pointer",
  };

  return (
    <div style={iconButtonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div style={tooltipStyle}>
        {props.tooltipText}
      </div>
      <a style={linkStyle} className={props.className} type={props.type} onClick={props.onClick}>
        <Icon icon={props.icon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
      </a>
    </div>
  );
};

export default IconButton;
