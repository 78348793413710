import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  error: null,
  loading: false,
  changeStatusLoading: false,
  CompanyName: "",
  RegistrationNo: "",
  BusinessType: "",
  CompanyAddress: "",
  Country: "",
  ContactPerson: "",
  ContactEmail: "",
  ContactNumber: "",
  Description: "",
  PartnershipType: "",
  ApplicationStatus: ""
};

const start = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const success = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    changeStatusLoading: false,
    CompanyName: action.info.CompanyName,
    RegistrationNo: action.info.RegistrationNo,
    BusinessType: action.info.BusinessType,
    CompanyAddress: action.info.CompanyAddress,
    Country: action.info.Country,
    ContactPerson: action.info.ContactPerson,
    ContactEmail: action.info.ContactEmail,
    ContactNumber: action.info.ContactNumber,
    Description: action.info.Description,
    PartnershipType: action.info.PartnershipType,
    ApplicationStatus: action.info.ApplicationStatus
  });
};

const fail = (state, action) => {
  return updateObject(state, {
    loading: false,
    changeStatusLoading: false,
    error: action.error
  });
};

const changeStatusStart = (state, action) => {
  return updateObject(state, {
    changeStatusLoading: true,
    loading: false
  });
};

const changeStatusSuccess = (state, action) => {
  return updateObject(state, {
    changeStatusLoading: false,
    ApplicationStatus: action.info.ApplicationStatus
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PARTNER_APPLICATION_DETAIL_SUCCESS:
      return success(state, action);
    case actionTypes.LOAD_PARTNER_APPLICATION_DETAIL_FAIL:
      return fail(state, action);
    case actionTypes.LOAD_PARTNER_APPLICATION_DETAIL_START:
      return start(state, action);
    case actionTypes.CHANGE_STATE_PARTNER_APPLICATION_DETAIL_SUCCESS:
      return changeStatusSuccess(state, action);
    case actionTypes.CHANGE_STATE_PARTNER_APPLICATION_DETAIL_FAIL:
      return fail(state, action);
    case actionTypes.CHANGE_STATE_PARTNER_APPLICATION_DETAIL_START:
      return changeStatusStart(state, action);
    default:
      return state;
  }
};

export default reducer;
