import React from "react";
import classNames from "classnames";

const Form = props => {
  const className = () => {
    return classNames(
      props.classNames,
      "container border rounded shadow-sm p-4 "
    );
  };

  return (
    <form
      className={className()}
      onSubmit={
        props.onSubmit !== null && props.onSubmit !== undefined
          ? props.onSubmit
          : event => {
              event.preventDefault();
            }
      }
    >
      {props.children}
    </form>
  );
};

export default Form;
