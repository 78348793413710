import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";
import infoActions from "../../helpers/infoActions";
import actActions from "../../helpers/actActions";
const AsssignActions = infoActions("ASSIGN_ROLE");
const SubmitActions = actActions("ASSIGN_ROLE_SUBMIT");

// export const assignRoleStart = () => {
//   console.log("ASSIGN_ROLE_START");
//   return {
//     type: actionTypes.ASSIGN_ROLE_START
//   };
// };

// export const assignRoleGetSuccess = roleList => {
//   return {
//     type: actionTypes.ASSIGN_ROLE_SUCCESS,
//     roleList: roleList
//   };
// };

// export const assignRoleFail = error => {
//   return {
//     type: actionTypes.ASSIGN_ROLE_FAIL,
//     error: error
//   };
// };

// export const assignRoleSubmitSuccess = roleList => {
//   return {
//     type: actionTypes.ASSIGN_ROLE_SUBMIT_SUCCESS,
//     message: "Role added successfuly"
//   };
// };

export const selectAssignRole = roleId => {
  return {
    type: actionTypes.ASSIGN_ROLE_SELECT,
    selectedRoles: roleId
  };
};

export const addRole = roleId => {
  return {
    type: actionTypes.ASSIGN_ROLE_ADD,
    roleId: roleId
  };
};

export const removeRole = roleId => {
  return {
    type: actionTypes.ASSIGN_ROLE_REMOVE,
    roleId: roleId
  };
};

export const getAssignRoles = roleId => {
  var url = `/user/${roleId}/roles`;
  return dispatch => {
    dispatch(AsssignActions.start());
    service
      .get(url)
      .then(res => {
        dispatch(AsssignActions.success(res.data));
      })
      .catch(err => {
        dispatch(AsssignActions.fail(err));
      });
  };
};

export const submit = (userId, rolesId) => {
  var url = `/user/${userId}/roles`;
  return dispatch => {
    dispatch(SubmitActions.start());
    service
      .post(url, { r_ids: rolesId })
      .then(res => {
        dispatch(SubmitActions.success(res.data));
      })
      .catch(err => {
        dispatch(SubmitActions.fail(err));
      });
  };
};
