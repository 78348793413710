import _ from "lodash";
import React from "react";
import { updateObject } from "../../helpers/utility";
import Column from "./Column";
import Paging from "./Paging";
import Row from "./Row";
import RowHeader from "./RowHeader";

const DataGrid = ({
  selectable = true,
  sortable = true,
  multiSelect = false,
  header = true,
  ...props
}) => {
  const onSort = (sortBy, sortDir) => {
    const currentFilterOptions = updateObject(props.gridState.filterOptions, {
      sortBy,
      sortDir,
    });

    props.on(
      "Sort",
      { sortBy, sortDir },
      {
        ...props.gridState,
        filterOptions: currentFilterOptions,
      },
      true,
    );
  };

  const onSelect = (id, row) => {
    props.on(
      "Select",
      { id, row },
      {
        ...props.gridState,
        selectedIDs: id,
        selectedRows: row,
      },
      false,
    );
  };
  const onCheckboxClick = (id, row, action) => {
    props.on(
      "CheckboxClick",
      { id, row, action },
      {
        ...props.gridState,
        selectedIDs: id,
        selectedRows: row,
      },
      false,
    );
  };

  const onPaging = (event, page) => {
    const currentFilterOptions = updateObject(props.gridState.filterOptions, {
      currentPage: page,
    });

    props.on(
      "Paging",
      { page },
      {
        ...props.gridState,
        filterOptions: currentFilterOptions,
      },
      true,
    );
  };

  const setData = (data, keyField, keyFieldValue, field, value) => {
    let d = data;
    let where = {};
    where[keyField] = keyFieldValue;
    let row = _.find(d, where);
    d[d.indexOf(row)][field] = value;

    return d;
  };

  const onEdit = (id, col, value) => {
    console.log(
      "DataGrid",
      "onEdit",
      "id:" + id,
      "col:" + col,
      "value:" + value,
    );
    const currentData = setData(
      props.gridState.data,
      props.keyField,
      id,
      col,
      value,
    );

    props.on(
      "RowEdit",
      { id, col, value },
      {
        ...props.gridState,
        data: currentData,
      },
      false,
    );
  };

  const onButtonClick = (id, action) => {
    props.on("ButtonClick", { id, action }, props.gridState, false);
  };

  // ---------------------------------------------------------

  let colsAsChildren = React.Children.map(props.children, (thisArg) => {
    if (thisArg.type === Column) {
      return { ...thisArg.props };
    }
  });

  let cols = [];
  if (props.cols) {
    cols.push(...props.cols);
  }
  if (colsAsChildren) {
    cols.push(...colsAsChildren);
  }

  const ascDesc = (col) => {
    if (
      props.gridState.filterOptions.sortBy === null ||
      props.gridState.filterOptions.sortBy === undefined
    ) {
      return "asc";
    } else if (props.gridState.filterOptions.sortBy === col) {
      return props.gridState.filterOptions.sortDir === "asc" ? "desc" : "asc";
    } else {
      return "asc";
    }
  };

  const isSelected = (col) => {
    let result =
      props.gridState.selectedIDs !== null &&
      props.gridState.selectedIDs !== undefined
        ? multiSelect
          ? props.gridState.selectedIDs.includes(col)
          : props.gridState.selectedIDs === col
        : false;
    return result;
  };
  const rowsarr = () => {
    return props.gridState.data.map((row, index) => {
      // alert(props.gridState.data[index][0]);
      return (
        <Row
          cols={cols}
          colsData={props.gridState.data[index]}
          onSelect={onSelect}
          onButtonClick={onButtonClick}
          onCheckboxClick={onCheckboxClick}
          key={index} //props.gridState.data[index][props.keyField]
          id={props.gridState.data[index][props.keyField]}
          selectable={selectable}
          isSelected={isSelected(props.gridState.data[index][props.keyField])}
          keyField={props.keyField}
          onChange={onEdit}
        />
      );
    });
  };

  const isData = (n) => {
    return (
      props.gridState.data !== null &&
      props.gridState.data !== undefined &&
      props.gridState.data.length > n
    );
  };

  return (
    <>
      <table className="table table-hover">
        <thead>
          {header && (
            <RowHeader
              cols={cols}
              onSort={(e, col) => sortable && onSort(col, ascDesc(col))}
            />
          )}
        </thead>
        <tbody>{isData(0) && rowsarr()}</tbody>
      </table>
      {isData(0) && props.gridState.total > 10 && (
        <Paging
          currentPage={props.gridState.filterOptions.currentPage}
          perPage={props.gridState.filterOptions.pageSize}
          total={props.gridState.total}
          onChange={onPaging}
        />
      )}
    </>
  );
};
export default DataGrid;
