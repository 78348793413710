import { lazy } from "react";

const Products = lazy(() => import("./containers/Products/Products"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const PartnerInbox = lazy(() => import("./containers/Partners/Inbox"));
const UsersIndex = lazy(() => import("./containers/Users/index"));
const Profile = lazy(() => import("./containers/Users/profile"));
const Revenue = lazy(() => import("./containers/report/Revenue"));
const Instances = lazy(() => import("./containers/workflow/Instances"));
const IssueTicket = lazy(() => import("./containers/BackOffice/IssueTicket"));
const Vouchers = lazy(() => import("./containers/NewVouchers/VouchersLanding"));

export default [
  {
    title: "Dashboard",
    path: "/dashboard/index",
    main: "dashboard",
    order: 1,
    component: Dashboard,
    permissions: ["15"],
  },
  {
    title: "Products",
    path: "/products/products",
    main: "products",
    order: 2,
    component: Products,
    permissions: ["23"],
  },
  {
    title: "Vouchers",
    path: "/vouchers",
    main: "vouchers",
    order: 2,
    component: Vouchers,
    permissions: ["23"],
  },
  {
    title: "Partners",
    path: "/partners/inbox",
    main: "partners",
    component: PartnerInbox,
    order: 3,
    permissions: ["31"],
  },
  {
    title: "Users",
    path: "/users/index",
    main: "users",
    component: UsersIndex,
    order: 4,
    permissions: [
      "1",
      "2",
      "3",
      "22",
      "21",
      "5",
      "6",
      "11",
      "12",
      "13",
      "16",
      "18",
      "19",
      "20",
      "25",
    ],
  },
  {
    title: "Report",
    path: "/report/revenue",
    main: "report",
    component: Revenue,
    order: 5,
    permissions: ["31"],
  },
  {
    title: "Inbox",
    path: "/workflow/instances",
    main: "inbox",
    component: Instances,
    order: 6,
    permissions: ["72"],
  },
  {
    title: "BackOffice",
    path: "/back-office/issue-ticket",
    main: "backoffice",
    component: IssueTicket,
    order: 1,
    permissions: ["72", "80","84","86"],
  },
  {
    title: "Setting",
    path: "/profile",
    main: "Setting",
    order: 6,
    component: Profile,
    permissions: ["15"],
  },
];
