import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const celLabel = (props) => {
  const renderCol = (value) => {
    switch (props.format) {
      case "text":
        return value;
      case "datetime":
        try {
          if (value === null) return "";
          return moment(value).format("YYYY-MM-DD HH:mm");
        } catch (error) {
          return "";
        }        
      case "date":
        return moment(value).format("YYYY-MM-DD");
      case "money":
        return value + " Rm";
      default:
        return value;
    }
  };
  return (
    <td style={props.width && { width: props.width }}>
      <div>{renderCol(props.value)}</div>
    </td>
  );
};

export default celLabel;

celLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.oneOf(["text", "datetime", "date", "money"]),
};
