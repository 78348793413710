import service from "../../services/log.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_USER_ACTIVITY");

export const selectUserActivityApplication = row => {
  return {
    type: actionTypes.LIST_USER_ACTIVITY_SELECT,
    selectedRow: row
  };
};

export const listUserActivity = (filterOptions, isExport = false) => {
  const url = `/log?page_number=${filterOptions.currentPage}&page_size=${
    filterOptions.page_size
  }&log_application=${filterOptions.application.value}&sort_by=${
    filterOptions.sort_by
  }&sort_dir=${filterOptions.sort_dir}${
    filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
  }${filterOptions.searchKeyword}&log_userName=${filterOptions.userName}`;
  console.log(url);
  return dispatch => {
    dispatch(ListActions.start(isExport));
    service
      .get(url)
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
