import React from "react";

const Searchbox = props => {
  const handleChange = e => {
      props.onSearch(e.target.value);
  };
  return (
    <div className="form-group">
      
      <input
        type="text"
        className="form-control"
        placeholder={props.placeholder || "Search"}
        onChange={handleChange}
      />
    </div>
  );
};

export default Searchbox;
