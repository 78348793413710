import service from "../../services/loyalty.servic";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_LOYALTY_RULE");

export const selectLoyaltyRule = (rowId, row) => {
  return {
    type: actionTypes.LIST_LOYALTY_RULE_SELECT,
    selectedRow: rowId,
    selectedRowData: row
  };
};

export const listLoyaltyRule = (filterOptions, isExport = false) => {
  return dispatch => {
    dispatch(ListActions.start(isExport));
    service
      .get(
        `/loyalty/rules?page_number=${filterOptions.currentPage}&page_size=${
          filterOptions.page_size
        }&sort_by=${filterOptions.sort_by}&sort_dir=${filterOptions.sort_dir}${
          filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
        }${filterOptions.searchKeyword}`
      )
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
