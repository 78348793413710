import * as actionTypes from "../actions/actionTypes";
import infoReducer from "../../helpers/infoReducer";
import { updateObject } from "../../helpers/utility";
const InfoReducer = infoReducer("Info");
const InfoByIdReducer = infoReducer("FilteredTemplate");
const InfoAssignTempleteReducer = infoReducer("CurrentTemplate");

const RenderReducer = infoReducer("RenderdHtml");

const initialState = {
  Info: null,
  loading: null
};

const addSuccess = (state, action) => {
  InfoReducer.success(state, action);
  return updateObject(state, {
    status: "success"
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FIND_TEMPLATE_SUCCESS:
      return InfoReducer.success(state, action);
    case actionTypes.FIND_TEMPLATE_FAIL:
      return InfoReducer.fail(state, action);
    case actionTypes.FIND_TEMPLATE_START:
      return InfoReducer.start(state, action);

    case actionTypes.ADD_TEMPLATE_SUCCESS:
      return addSuccess(state, action);
    case actionTypes.ADD_TEMPLATE_FAIL:
      return InfoReducer.fail(state, action);
    case actionTypes.ADD_TEMPLATE_START:
      return InfoReducer.start(state, action);

    case actionTypes.RENDER_TEMPLATE_SUCCESS:
      return RenderReducer.success(state, action);
    case actionTypes.RENDER_TEMPLATE_FAIL:
      return RenderReducer.fail(state, action);
    case actionTypes.RENDER_TEMPLATE_START:
      return RenderReducer.start(state, action);

    case actionTypes.FIND_BY_ID_TEMPLATE_SUCCESS:
      return InfoByIdReducer.success(state, action);
    case actionTypes.FIND_BY_ID_TEMPLATE_FAIL:
      return InfoByIdReducer.fail(state, action);
    case actionTypes.FIND_BY_ID_TEMPLATE_START:
      return InfoByIdReducer.start(state, action);

    case actionTypes.FIND_BY_Assign_TEMPLATE_SUCCESS:
      return InfoAssignTempleteReducer.success(state, action);
    case actionTypes.FIND_BY_Assign_TEMPLATE_FAIL:
      return InfoAssignTempleteReducer.fail(state, action);
    case actionTypes.FIND_BY_Assign_TEMPLATE_START:
      return InfoAssignTempleteReducer.start(state, action);

    default:
      return state;
  }
};

export default reducer;
