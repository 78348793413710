import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const addUserSuccess = data => {
  return {
    type: actionTypes.ADD_USERS_SUCCESS,
    addStatus: data
  };
};

export const updateUserSuccess = data => {
  return {
    type: actionTypes.UPDATE_USERS_SUCCESS,
    addStatus: data,
    message: "update successful"
  };
};

export const resetStatus = () => {
  return {
    type: actionTypes.LOGIN_RESET_STATUS
  };
};

export const controlOnChange = newForm => {
  return {
    type: actionTypes.ADD_USERS_CHANGE_FORM,
    newForm: newForm
  };
};
export const addUserFail = error => {
  return {
    type: actionTypes.ADD_USERS_FAIL,
    addStatus: error
  };
};

export const addUser = newUser => {
  console.log("addUserStore: " + JSON.stringify(newUser));
  return dispatch => {
    service
      .post("/user", newUser)
      .then(res => {
        dispatch(addUserSuccess(res.data));
      })
      .catch(err => {
        dispatch(addUserFail(err.message));
      });
  };
};

export const updateUser = (userId, updatedUser) => {
  return dispatch => {
    service
      .put(`/user/${userId}`, updatedUser)
      .then(res => {
        dispatch(updateUserSuccess(res.data));
      })
      .catch(err => {
        dispatch(addUserFail(err.message));
      });
  };
};
