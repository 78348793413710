import React from "react";
import "./checkbox.css";

const CustomCheckbox = (props) => {
  let { label, onChange } = props;
  return (
    <>
      <label className="checkbox-container">
        {label}
        <input type="checkbox" onChange={onChange} {...props} />
        <span className="checkbox-checkmark"></span>
      </label>
    </>
  );
};

export default CustomCheckbox;
