import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const availableRoleStart = () => {
  console.log("AVAILABLE_ROLE_START");
  return {
    type: actionTypes.AVAILABLE_ROLE_START
  };
};

export const availableRoleSuccess = roleList => {
  return {
    type: actionTypes.AVAILABLE_ROLE_SUCCESS,
    roleList: roleList
  };
};

export const availableRoleFail = error => {
  return {
    type: actionTypes.AVAILABLE_ROLE_FAIL,
    error: error
  };
};

export const selectAvailableRole = roleId => {
  return {
    type: actionTypes.AVAILABLE_ROLE_SELECT,
    selectedRoles: roleId
  };
};

export const addAvailableRole = selectedRole => {
  console.log("assignRole addAvailableRole " + selectedRole);
  return {
    type: actionTypes.AVAILABLE_ROLE_ADD,
    selectedRole: selectedRole
  };
};

export const removeAvailableRole = roleId => {
  return {
    type: actionTypes.AVAILABLE_ROLE_REMOVE,
    roleId: roleId
  };
};

export const getAvailableRoles = roleId => {
  console.log("availableRole getAvailableRoles " + roleId);
  var url = `/user/${roleId}/roles/exclude`;
  console.dir("availableRole getAvailableRoles " + url);
  return dispatch => {
    dispatch(availableRoleStart());
    service
      .get(url)
      .then(res => {
        console.log(
          "availableRoles availableRoleSuccess res.Data: " +
            JSON.stringify(res.data, null, 2)
        );
        dispatch(availableRoleSuccess(res.data));
      })
      .catch(err => {
        console.log("Service Error ---------------------------------------");
        console.log(err);
        dispatch(availableRoleFail(err));
      });
  };
};
