import React from "react";
import classNames from "classnames";

const Checkbox = (props) => {
  
  return (
    <div className={props.className + " form-check"}>
      <input
        className={classNames({
          "Is-Invalid": true,
          "form-check-input": true,
        })}
        type="checkbox"
        checked={props.checked}
        id={props.Id}
        onChange={(event) =>
          props.onChange && props.onChange(props.id, event.target.checked)
        }
      />
      <label
        className={classNames({
          "Is-Invalid": !props.valid && props.isSubmited,
          "form-check-label": true,
        })}
      >
        {props.label}
      </label>
    </div>
  );
};

export default Checkbox;
