import React from "react";
import PropTypes from "prop-types";
import { List, Row, Col, Button, Dropdown } from "./";
import { isNotNull } from "../helpers/utility";
import _ from "lodash";

const MultiSelect = props => {
  const onDropdownChangeHandler = e => {
    if (props.on) {
      props.on(
        "DDLChange",
        { e },
        { ...props.state, selectedOption: e },
        false
      );
    }
  };

  const isSelected = op => {
    console.log("OP", op);
    return (
      op !== undefined &&
      op !== null &&
      op.value !== undefined &&
      op.label !== undefined
    );
  };

  const onAddHandler = e => {
    if (isSelected(props.state.selectedOption)) {
      let newOptions = props.state.options;
      _.pull(newOptions, props.state.selectedOption);

      let newList = props.state.list !== undefined ? props.state.list : [];

      newList.push(props.state.selectedOption);

      if (props.on) {
        props.on(
          "Add",
          props.state.selectedOption,
          {
            ...props.state,
            selectedOption: null,
            options: newOptions,
            list: newList
          },
          false
        );
      }
    }
  };
  const onRemoveHandler = e => {
    let newList = props.state.list;
    _.pull(newList, props.state.selectedRow);

    let newOptions =
      props.state.options !== undefined ? props.state.options : [];

    newOptions.push(props.state.selectedRow);

    if (props.on) {
      props.on(
        "Remove",
        props.state.selectedRow,
        {
          ...props.state,
          selectedOption: {},
          selectedRow: {},
          options: newOptions,
          list: newList
        },
        false
      );
    }
  };
  const onSelectHandler = e => {
    const selectedRow = _.find(props.state.list, { value: e });
    if (props.on) {
      props.on(
        "ListRowSelected",
        { selectedRow },
        { ...props.state, selectedRow: selectedRow },
        false
      );
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-9">
              <Dropdown
                Id="ddl"
                placeholder="Please select ..."
                label={props.label}
                value={props.state.selectedOption}
                onChange={e => onDropdownChangeHandler(e)}
                options={props.state.options}
              />
            </div>
            <div className="col-md-3 mt-4">
              <div className="row mt-1">
                <div className="col-6">
                  <Button
                    type="button"
                    label="Add"
                    className="btn btn-info btn-block"
                    onClick={onAddHandler}
                    disabled={!isSelected(props.state.selectedOption)}
                  />
                </div>
                <div className="col-6">
                  <Button
                    type="button"
                    label="Remove"
                    className="btn btn-danger btn-block"
                    onClick={e => onRemoveHandler(e)}
                    disabled={!isSelected(props.state.selectedRow)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {props.state.list === undefined ? (
            <p>No reocrd found</p>
          ) : (
            <List
              data={props.state.list}
              fieldName="label"
              keyField="value"
              onSelect={e => onSelectHandler(e)}
              selectedRows={
                props.state.selectedRow ? props.state.selectedRow.value : null
              }
              multiSelect={false}
              whiteSelected={false}
              checkbox={true}
              highlight={false}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default MultiSelect;

MultiSelect.propTypes = {
  //name: PropTypes.string.isRequired
};
