import joi from "joi";

const form = () => {
  const checkValidity = function (value, validation) {
    let result = validation !== null ? joi.validate(value, validation) : true;
    return result;
  };

  const updateControl = function (controlName, value, ctrType, State) {
    var validateResult = checkValidity(
      ctrType === undefined
        ? value
        : ctrType === "checkbox"
        ? value
        : value.value,
      State.controls[controlName].validation,
    );
    const updatedControls = {
      ...State.controls,
      [controlName]: {
        ...State.controls[controlName],
        value: value,
        valid:
          State.controls[controlName].disable ||
          (validateResult.error ? false : true),
        validMessage: validateResult.error
          ? validateResult.error.details[0].message
          : "",
        touched: true,
      },
    };
    return updatedControls;
  };

  const isData = (data, n) => {
    return data !== null && data !== undefined && data.length > n;
  };
  return {
    updateControl,
    isData,
  };
};

export default form;
