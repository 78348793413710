import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  error: null,
  loading: false,
  changeStatusLoading: false,
  VoucherTitle: "",
  VoucherType: "",
  IssueBy: "",
  VoucherStatus: "",
  PromoCode: "",
  IsExternal: "",
  UseType: "",
  LimitTotal: "",
  LimitPerDay: "",
  Description: "",
  VoucherValue: "",
  Price: "",
  ValidFrom: "",
  ValidTo: "",
  ValidityType: "",
  ValidityDuration: 0,
  TemplateId: 0,
  rangeInfo: {},
};

const start = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const success = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    changeStatusLoading: false,
    VoucherTitle: action.info.VoucherTitle,
    VoucherType: action.info.VoucherType,
    IssueBy: action.info.IssueBy,
    VoucherStatus: action.info.VoucherStatus,
    PromoCode: action.info.PromoCode,
    IsExternal: action.info.IsExternal,
    UseType: action.info.UseType,
    LimitTotal: action.info.LimitTotal,
    LimitPerDay: action.info.LimitPerDay,
    Description: action.info.Description,
    VoucherValue: action.info.VoucherValue,
    Price: action.info.Price,
    ValidFrom: action.info.ValidFrom,
    ValidTo: action.info.ValidTo,
    ValidityType: action.info.ValidityType,
    ValidityDuration: action.info.ValidityDuration,
    TemplateId: action.info.TemplateId * 1,
    rangeInfo: action.info.rangeInfo,
    CreatedDate: action.info.CreatedDate,
    CreatedBy: action.info.CreatedBy,
  });
};

const fail = (state, action) => {
  return updateObject(state, {
    loading: false,
    changeStatusLoading: false,
    error: action.error,
  });
};

const changeStatusStart = (state, action) => {
  return updateObject(state, {
    changeStatusLoading: true,
    loading: false,
  });
};

const changeStatusSuccess = (state, action) => {
  return updateObject(state, {
    changeStatusLoading: false,
    VoucherStatus: action.info.VoucherStatus,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VOUCHER_INFO_SUCCESS:
      return success(state, action);
    case actionTypes.LOAD_VOUCHER_INFO_FAIL:
      return fail(state, action);
    case actionTypes.LOAD_VOUCHER_INFO_START:
      return start(state, action);
    case actionTypes.CHANGE_STATUS_VOUCHER_INFO_SUCCESS:
      return changeStatusSuccess(state, action);
    case actionTypes.CHANGE_STATUS_VOUCHER_INFO_FAIL:
      return fail(state, action);
    case actionTypes.CHANGE_STATUS_VOUCHER_INFO_START:
      return changeStatusStart(state, action);
    default:
      return state;
  }
};

export default reducer;
