import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  listData: null,
  listExport: null,
  error: null,
  loading: false,
  loadingExport: false,
  sort_by: "ModifiedBy",
  sort_dir: "desc",
  searchKeyword: "",
  selectedRow: null,
  currentPage: 1,
  total: 0
};

const listExportStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loadingExport: true,
    listExport: null
  });
};

const listExportSuccess = (state, action) => {
  return updateObject(state, {
    listExport: action.list,
    loadingExport: false,
    error: null
  });
};

const listStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    listExport: null
  });
};

const listSuccess = (state, action) => {
  return updateObject(state, {
    listData: action.list,
    applicationStatus: action.filterOptions.applicationStatus,
    loading: false,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    selectedRow: null,
    currentPage: action.filterOptions.currentPage,
    total: action.total,
    error: null,
    loadingExport: false,
    listExport: null
  });
};

const listFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    applicationStatus: action.filterOptions.applicationStatus,
    sort_by: action.filterOptions.sort_by,
    sort_dir: action.filterOptions.sort_dir,
    searchKeyword: action.filterOptions.searchKeyword,
    currentPage: action.filterOptions.currentPage,
    listExport: null,
    error: action.error
  });
};

const selectRow = (state, action) => {
  return updateObject(state, {
    selectedRow: action.selectedRow
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_WORKFLOW_LOG_SUCCESS:
      return listSuccess(state, action);
    case actionTypes.LIST_WORKFLOW_LOG_FAIL:
      return listFail(state, action);
    case actionTypes.LIST_WORKFLOW_LOG_START:
      return listStart(state, action);
    case actionTypes.LIST_WORKFLOW_LOG_SELECT:
      return selectRow(state, action);
    case actionTypes.LIST_WORKFLOW_LOG_EXPORT_START:
      return listExportStart(state, action);
    case actionTypes.LIST_WORKFLOW_LOG_EXPORT_SUCCESS:
      return listExportSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
