import React from "react";
import Row from "./Row";
import RowHeader from "./RowHeader";
import Paging from "./Paging";
import Column from "./Column";

const Gridview = ({ selectable = true, multiSelect = false, ...props }) => {
  let colsAsChildren = React.Children.map(props.children, thisArg => {
    if (thisArg.type === Column) {
      return { ...thisArg.props };
    }
  });

  let cols = [];
  if (props.cols) {
    cols.push(...props.cols);
  }
  if (colsAsChildren) {
    cols.push(...colsAsChildren);
  }

  const ascDesc = col => {
    if (props.sortBy === null || props.sortBy === undefined) {
      return "asc";
    } else if (props.sortBy === col) {
      return props.sortDir === "asc" ? "desc" : "asc";
    } else {
      return "asc";
    }
  };

  const isSelected = col => {
    return props.selectedRows !== null && props.selectedRows !== undefined
      ? multiSelect
        ? props.selectedRows.includes(col)
        : props.selectedRows === col
      : false;
  };
  const rowsarr = () => {
    return props.data.map((row, index) => {
      // alert(props.data[index][0]);
      return (
        <Row
          cols={cols}
          colsData={props.data[index]}
          onSelect={props.onSelect}
          onButtonClick={props.onButtonClick}
          key={props.data[index][props.keyField]}
          id={props.data[index][props.keyField]}
          selectable={selectable}
          isSelected={isSelected(props.data[index][props.keyField])}
          keyField={props.keyField}
        />
      );
    });
  };

  const isData = n => {
    return (
      props.data !== null && props.data !== undefined && props.data.length > n
    );
  };

  const header = n => {
    return props.header === null || props.header === undefined
      ? true
      : props.header;
  };

  return (
    <>
      <table className="table table-hover">
        <thead>
          {header() && (
            <RowHeader
              cols={cols}
              onSort={(e, col) => props.onSort(col, ascDesc(col))}
            />
          )}
        </thead>
        <tbody>{isData(0) && rowsarr()}</tbody>
      </table>
      {isData(0) && props.total > 10 && (
        <Paging
          currentPage={props.currentPage}
          perPage="10"
          total={props.total}
          onChange={props.onPaging}
        />
      )}
    </>
  );
};
export default Gridview;
