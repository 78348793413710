import voucherService from "../../services/voucher.service";

const newVoucherAPI = () => {
  const getVoucherList = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getAllVoucherListWithFilter/0/0/0/0`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getAllPendingApproveVoucherList = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getAllPendingApproveVoucherList/0/0/0`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getPartners = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/partners`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getCSV = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/download/ERL_sample_bulk_upload.csv`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const isTitleExist = (voucherTitle) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/isTitleExist/` + voucherTitle)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getAllTemplateListWithFilter = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getAllTemplateListWithFilter/0/0`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getAllTemplateListWithFilterBySelect = (typeID, channelID) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getAllTemplateListWithFilter/${typeID}/${channelID}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const deleteTemplate = (templateID) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .delete(`voucher/deleteTemplate/${templateID}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherCodeExist = (codePrefix, codeValue) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getVoucherCodeExist/` + codePrefix + codeValue)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherDetails = (voucherID) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getVoucherByID/${voucherID}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherUsage = (voucherID) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getVoucherUsage/${voucherID}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherHistory = (voucherID) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getVoucherHistory/${voucherID}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const originTypeHandler = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/OriginTypeChannel`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const getApprovalListCount = () => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getAllPendingApproveVoucherListCount`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherCodes = (voucherID) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(`voucher/getVoucherCodes/${voucherID}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherCodeList = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/getVoucherCodeList`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const getVoucherApprovalList = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/getAllVoucherList`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getAllPendingApproveVoucherListWithFilter = (
    originID,
    channelID,
    typeID
  ) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(
          `voucher/getAllPendingApproveVoucherList/${originID}/${channelID}/${typeID}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const confirmDeleteVoucher = (id) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .delete(`voucher/deleteVoucher/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const uploadTemplate = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/createTemplate`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const getAllVoucherListWithFilter = (
    originID,
    distributionID,
    typeID,
    statusID
  ) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .get(
          `voucher/getAllVoucherListWithFilter/${originID}/${distributionID}/${typeID}/${statusID}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const voucherStatusUpdate = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/voucherStatusUpdate`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const voucherExternalBulkCode = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/VoucherExternalBulkCode`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const voucherSingleCodeUpload = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/VoucherSingleCode`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const UpdateVoucherCode = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/UpdateVoucherCode`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const voucherBulkCodeUpload = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/VoucherBulkCode`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const saveVoucher = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/saveVoucher`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };
  const updateVoucher = (data) => {
    return new Promise(function (resolve, reject) {
      voucherService
        .post(`voucher/updateVoucher`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  };

  return {
    isTitleExist,
    saveVoucher,
    updateVoucher,
    getApprovalListCount,
    getVoucherList,
    confirmDeleteVoucher,
    getAllVoucherListWithFilter,
    originTypeHandler,
    voucherStatusUpdate,
    getAllPendingApproveVoucherList,
    getAllPendingApproveVoucherListWithFilter,
    getVoucherDetails,
    getVoucherUsage,
    getVoucherHistory,
    voucherExternalBulkCode,
    voucherSingleCodeUpload,
    voucherBulkCodeUpload,
    UpdateVoucherCode,
    getVoucherCodeExist,
    getAllTemplateListWithFilter,
    getAllTemplateListWithFilterBySelect,
    deleteTemplate,
    uploadTemplate,
    getVoucherCodes,
    getVoucherCodeList,
    getVoucherApprovalList,
    getPartners, getCSV
  };
};
export default newVoucherAPI;
