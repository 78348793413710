import React from "react";
import { Button } from "../index";
import PropTypes from "prop-types";

var classNames = require("classnames");

const celButton = (props) => {
  return (
    <td>
      <Button
        label={props.name}
        className={classNames({
          btn: true,
          "btn-primary":
            props.className === null ||
            props.className === undefined ||
            props.className === "btn-primary",
          "btn-warning": props.className === "btn-warning",
          "btn-secondary": props.className === "btn-secondary",
          "btn-danger": props.className === "btn-danger",
        })}
        onClick={(event) => {
          props.onClick(event, "buttonClick", props.id, props.action);
        }}
        disabled={props.disabled}
      />
    </td>
  );
};

export default celButton;

celButton.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.oneOf([true, false, 0, 1]),
};
