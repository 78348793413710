import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  assignRoleList: null,
  assignRoleListOrginal: null,
  error: null,
  loading: false,
  selectedRole: null,
  message: ""
};

const assignRoleStart = (state, action) => {
  console.log("assignRoleStart");
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const assignRoleSuccess = (state, action) => {
  let _assignRoleListOrginal = action.info.map(item => {
    return item;
  });
  return updateObject(state, {
    assignRoleListOrginal: _assignRoleListOrginal,
    assignRoleList: action.info,
    loading: false,
    selectedRole: null,
    error: null,
    message: ""
  });
};

const assignRoleSubmitSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    message: "Role added successfuly"
  });
};
const assignRoleFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const addAssignRole = (state, action) => {
  let newRole = {
    ur_u_id: "7",
    ur_r_id: action.roleId.value,
    r_title: action.roleId.label,
    ur_modifiedDate: "2019-04-26T03:19:58.972Z",
    ur_modifiedBy: "2019-04-26T03:19:58.972Z"
  };
  console.log("assignRole reducer roleId " + JSON.stringify(action.roleId));
  console.log("assignRole reducer a " + JSON.stringify(state.assignRoleList));
  return { ...state, assignRoleList: state.assignRoleList.concat(newRole) };
};

const removeAssignRole = (state, action) => {
  let roles = state.assignRoleList.filter(e => e.ur_r_id !== action.roleId);
  console.log(
    "assignRole reducer removeAssignRole roleId " +
      JSON.stringify(action.roleId)
  );
  console.log("assignRole reducer removeAssignRole a " + JSON.stringify(roles));
  return {
    ...state,
    assignRoleList: roles,
    selectedRole: null
  };
};

const selectAssignRole = (state, action) => {
  console.log("Select Role Reducer " + action.selectedRoles);
  return updateObject(state, {
    selectedRole: action.selectedRoles
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ASSIGN_ROLE_SUCCESS:
      return assignRoleSuccess(state, action);
    case actionTypes.ASSIGN_ROLE_FAIL:
      return assignRoleFail(state, action);
    case actionTypes.ASSIGN_ROLE_START:
      return assignRoleStart(state, action);
    case actionTypes.ASSIGN_ROLE_SELECT:
      return selectAssignRole(state, action);
    case actionTypes.ASSIGN_ROLE_ADD:
      return addAssignRole(state, action);
    case actionTypes.ASSIGN_ROLE_REMOVE:
      return removeAssignRole(state, action);
    case actionTypes.ASSIGN_ROLE_SUBMIT_SUCCESS:
      return assignRoleSubmitSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
