import React from "react";
import Alert from "./Alert";

const ErrorMessage = props => {
  let errMessage = "",
    errStatus = "";

  const prepareMessage = () => {
    if (props.errorMessage !== null && props.errorMessage !== undefined) {
      errMessage = props.errorMessage;
      errStatus = props.errorStatus;
    } else if (props.error !== null && props.error !== undefined) {
      errMessage = props.error;
      errStatus = "danger";
    } else if (props.info !== null && props.info !== undefined) {
      errMessage = props.info;
      errStatus = "info";
    } else if (props.warning !== null && props.warning !== undefined) {
      errMessage = props.warning;
      errStatus = "warning";
    } else if (props.success !== null && props.success !== undefined) {
      errMessage = props.success;
      errStatus = "success";
    }
  };

  return (
    <>
      {prepareMessage()}
      <Alert status={errStatus} message={errMessage} {...props} />
    </>
  );
};

export default ErrorMessage;
