import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const loadUserInfoStart = () => {
  return {
    type: actionTypes.LOAD_USERINFO_START
  };
};

export const loadUserInfoSuccess = userInfo => {
  return {
    type: actionTypes.LOAD_USERINFO_SUCCESS,
    userInfo: userInfo
  };
};

export const loadUserInfoFail = error => {
  return {
    type: actionTypes.LOAD_USERINFO_FAIL,
    error: error
  };
};

export const loadUsers = u_id => {
  console.log("Service call-----------------------------");
  console.dir(u_id);
  console.log(`/user/${u_id}/profile`);

  return dispatch => {
    dispatch(loadUserInfoStart());
    service
      .get(`/user/${u_id}/profile`)
      .then(res => {
        console.log("service executed..................................");
        console.dir(res.data);
        dispatch(loadUserInfoSuccess(res.data));
      })
      .catch(err => {
        console.log("Service Error ---------------------------------------");
        console.log(err);
        dispatch(loadUserInfoFail(err));
      });
  };
};

//----------------------------------------------------------------------------
export const changeUserStatusStart = () => {
  return {
    type: actionTypes.CHANGE_USER_STATUS_START
  };
};
export const changeUserStatusSuccess = data => {
  return {
    type: actionTypes.CHANGE_USER_STATUS_SUCCESS
  };
};

export const changeUserStatusFail = error => {
  return {
    type: actionTypes.CHANGE_USER_STATUS_FAIL,
    error: error
  };
};

export const changeUserStatus = (u_id, status) => {
  return dispatch => {
    dispatch(changeUserStatusStart());
    service
      .post(`/user/${u_id}/changestatus`, { u_status: status })
      .then(res => {
        console.log("changeUserStatus");
        dispatch(changeUserStatusSuccess(res.data));
      })
      .catch(err => {
        dispatch(changeUserStatusFail(err));
      });
  };
};
