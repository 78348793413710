import React from "react";
import PropTypes from "prop-types";

const Image = props => {
  return (
    <>
      {props.fileType && props.data && (
        <img
          src={"data:" + props.fileType + ";base64," + props.data}
          alt="product"
          className="img-fluid"
          style={{ ...props.style }}
        />
      )}
    </>
  );
};
export default Image;

Image.propTypes = {
  data: PropTypes.string,
  fileType: PropTypes.oneOf(["image/jpeg", "image/png"]),
  style: PropTypes.object
};
