import React from "react";
import packageJson from "../../package.json";

const Version = (props) => {
  return (
    <footer className="footer mt-5  ">
      <p className="pt-2  text-muted">
        Copyright © 2023 (Version {packageJson.version})
        {process.env.REACT_APP_ENV !== "prod" && (
          <small className="text-info">({process.env.REACT_APP_ENV})</small>
        )}
      </p>
    </footer>
  );
};

export default Version;
