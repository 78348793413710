import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import helper from "../../helpers/listReducer";
import InfoReducer from "../../helpers/infoReducer";
const infoReducer = InfoReducer("voidResult");
const Helper = helper();

const initialState = {
  ...Helper.initState(),
  fromDate: "",
  toDate: "",
  IsVoid: { value: "", label: "select..." },
  IsLock: { value: "", label: "select..." },
  Status: { value: "", label: "select..." },
  sort_by: "SerialNumber"
};

const listSuccess = (state, action) => {
  return updateObject(state, {
    fromDate: action.filterOptions.fromDate,
    toDate: action.filterOptions.toDate,
    selectedRow: state.selectedRow,
    Status: action.filterOptions.status,
    IsLock: action.filterOptions.isLock,
    IsVoid: action.filterOptions.isVoid,
    listPrint: null,
    ...Helper.successState(action, state)
  });
};

const printStart = (state, action) => {
  return updateObject(state, {
    printloading: true,
    error: null
  });
};

const printSuccess = (state, action) => {
  return updateObject(state, {
    listPrint: action.list,
    printloading: false,
    error: null
  });
};

const printFail = (state, action) => {
  return updateObject(state, {
    printloading: false,
    error: null
  });
};

const listFail = (state, action) => {
  return updateObject(state, {
    fromDate: action.filterOptions.fromDate,
    toDate: action.filterOptions.toDate,
    ...Helper.failState(action)
  });
};

const voidSuccess = (state, action) => {
  infoReducer.success(state, action);
  return updateObject(state, {
    selectedRow: null,
    voidResult: action.info.VoucherCodesID
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_VOUCHER_CODE_SUCCESS:
      return listSuccess(state, action);
    case actionTypes.LIST_VOUCHER_CODE_FAIL:
      return listFail(state, action);
    case actionTypes.LIST_VOUCHER_CODE_START:
      return Helper.start(state, action);
    case actionTypes.LIST_VOUCHER_CODE_SELECT:
      return Helper.select(state, action, true);
    case actionTypes.LIST_VOUCHER_CODE_EXPORT_START:
      return Helper.exportStart(state, action);
    case actionTypes.LIST_VOUCHER_CODE_EXPORT_SUCCESS:
      return Helper.exportSuccess(state, action);
    case actionTypes.VOID_VOUCHER_CODE_SUCCESS:
      return voidSuccess(state, action);
    case actionTypes.VOID_VOUCHER_CODE_FAIL:
      return infoReducer.fail(state, action);
    case actionTypes.VOID_VOUCHER_CODE_START:
      return infoReducer.start(state);
    case actionTypes.PRINT_VOUCHER_CODE_SUCCESS:
      return printSuccess(state, action);
    case actionTypes.PRINT_VOUCHER_CODE_FAIL:
      return printFail(state, action);
    case actionTypes.PRINT_VOUCHER_CODE_START:
      return printStart(state, action);

    default:
      return state;
  }
};

export default reducer;
