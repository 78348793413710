import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ProgressBar = props => {
  const percentage = Math.floor((props.value / (props.max - props.min)) * 100);

  return (
    <>
      <div className="progress">
        <div
          className={classNames(
            { "bg-success": percentage <= 60 },
            { "bg-warning": percentage >= 61 && percentage <= 90 },
            { "bg-danger": percentage >= 91 },
            "progress-bar",
            "progress-bar-striped"
          )}
          role="progressbar"
          style={{ width: percentage + "%" }}
          aria-valuenow={props.value}
          aria-valuemin={props.min}
          aria-valuemax={props.max}
        >
          {props.showLabel && percentage + "%"}
        </div>
      </div>
    </>
  );
};
export default ProgressBar;

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired
};
