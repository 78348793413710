export default service => {
  service.interceptors.request.use(
    req => {
      if (localStorage.getItem("token")) {
        req.headers.sessiontoken = localStorage.getItem("token");
        req.headers["x-api-key"] =
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOiJhZG1pbnBvcnRhbCIsImlhdCI6MTYzODYxNTU3N30.72e_5AhLNAWrpNW7EJo0ouwqyoNQ9gYOC8TazNirXZY";

        req.validateStatus = function(status) {
          return status >= 200 && status < 400;
        };
      }
      return req;
    },
    error => {
      return Promise.reject(error);
    }
  );

  service.interceptors.response.use(
    res => {
      return res;
    },
    error => {
      return Promise.reject(error);
    }
  );
};
