import React from "react";
import PropTypes from "prop-types";

const Card = (props) => {
  return (
    <>
      <div className={"card " + props.className}>
        <div class="card-body">{props.children} </div>
      </div>
    </>
  );
};

export default Card;

Card.propTypes = {
  name: PropTypes.string,
};
