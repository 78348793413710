import React from "react";
import { isNotNull } from "../helpers/utility";

const Button = (props) => {
  return (
    <button
      id={props.id && props.id}
      name={props.name && props.name}
      className={props.className + " btn"}
      type={props.type}
      onClick={(e) => props.onClick(e)}
      disabled={
        isNotNull(props.disabled) ? (props.disabled ? "disabled" : "") : ""
      }
    >
      {props.label}
    </button>
  );
};

export default Button;
