import axios from "axios";
import config from "../config";
import interceptor from "../helpers/Interceptor";

const loyaltyService = axios.create({
  baseURL: config("service").loyalty
});

interceptor(loyaltyService);

export default loyaltyService;
