import React from "react";
//var classNames = require('classnames');

const celLabel = props => {
  return (
    <th onClick={event => props.onSort(event, props.field)}>
      <div>{props.children}</div>
    </th>
  );
};

export default celLabel;
