import service from "../../services/voucher.service";
import * as actionTypes from "./actionTypes";
import listActions from "../../helpers/listActions";
const ListActions = listActions("LIST_VOUCHER");

export const selectVoucher = (rowId, row) => {
  return {
    type: actionTypes.LIST_VOUCHER_SELECT,
    selectedRow: rowId,
    selectedRowData: row
  };
};

export const listVouchers = (filterOptions, isExport = false) => {
  return dispatch => {
    dispatch(ListActions.start(isExport));
    service
      .get(
        `/voucher?page_number=${filterOptions.currentPage}&page_size=${
          filterOptions.page_size
        }&isExternal=${filterOptions.isExternal.value}&voucherType=${
          filterOptions.voucherType.value
        }&voucherStatus=${filterOptions.voucherStatus.value}&sort_by=${
          filterOptions.sort_by
        }&sort_dir=${filterOptions.sort_dir}${
          filterOptions.searchKeyword.length > 0 ? "&keyword=" : ""
        }${filterOptions.searchKeyword}`
      )
      .then(res => {
        dispatch(ListActions.success(res.data, filterOptions, isExport));
      })
      .catch(err => {
        dispatch(ListActions.fail(err, filterOptions));
      });
  };
};
