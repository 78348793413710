import React, { Component,useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import service from "../../services/userManagement.service";
import axiosInterceptors from "../../hoc/axiosInterceptors";
import Textbox from "../../components/Textbox";
import Checkbox from "../../components/Checkbox";
import Version from "../../components/Version";
import Button from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import Logo from "../../components/Logo";
import joi from "joi";
import Form from "../../components/Form";
import "./Login.css";
import { Spinner } from "../../components";

class Login extends Component {

  

  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  

  initialState = {
    isSubmit: false,
    controls: {
      username: {
        value: "",
        valid: false,
        validMessage: "",
        touched: false,
        validation: joi.string().required(),
      },
      password: {
        value: "",
        valid: false,
        validMessage: "",
        touched: false,
        validation: joi.string().required(),
      },
    },
  };
  componentDidMount(){
    sessionStorage.clear();
  }

  componentWillReceiveProps(nextProps) {
    this.resetForm();
  }

  resetForm() {
    this.setState(this.initialState);
  }

  onSubmitHandler = (event) => {
    event.preventDefault();
    this.setState({ ...this.state, isSubmit: true });
    Object.keys(this.state.controls).map((controlName) => {
      console.log(this.state.controls[controlName].valid);
      this.formIsValid =
        this.state.controls[controlName].valid && this.formIsValid;
        
    });

  

    if (this.formIsValid === true) {
      this.props.onLogin(
        this.state.controls.username.value,
        this.state.controls.password.value,
      );
    }
    
  };

  checkValidity = (value, validation) => {
    let result = joi.validate(value, validation);
    return result;
  };

  formIsValid = false;

  onChangeHandler = (controlName, value) => {
    this.formIsValid = true;
    var validateResault = this.checkValidity(
      value,
      this.state.controls[controlName].validation,
    );
    // this.formIsValid = validateResault.error ? false : true;
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: value,
        valid: validateResault.error ? false : true,
        validMessage: validateResault.error
          ? validateResault.error.details[0].message
          : "",
        touched: true,
      },
    };
    this.setState({ ...this.state, controls: updatedControls });
  };

  ForgotPasswordHandler = () => {
    this.props.onForgotPasswordStatus(true);
  };

  render() {
    
    return (
      <div className="body text-center wrapper">
        <Form classNames="form-signin">
          {this.props.loading && <Spinner />}
          <Logo />
          <h1 className="h3 mb-3 font-weight-normal">Please sign in</h1>
          <Textbox
            type="text"
            label=""
            placeholder="Username"
            id="username"
            onChange={this.onChangeHandler}
            value={this.state.controls.username.value}
            valid={this.state.controls.username.valid}
            validMessage={this.state.controls.username.validMessage}
            isSubmited={this.state.isSubmit}
          />
          <Textbox
            type="password"
            label=""
            placeholder="password"
            id="password"
            onChange={this.onChangeHandler}
            value={this.state.controls.password.value}
            valid={this.state.controls.password.valid}
            validMessage={this.state.controls.password.validMessage}
            isSubmited={this.state.isSubmit}
          />
          <Button
            // disable={this.formIsValid ? "" : disabled}
            className="btn btn-lg btn-primary btn-block "
            type="submit"
            label="Sign in"
            onClick={(event) => this.onSubmitHandler(event)}
          />
          <div className="col-md-12 p-0 mt-4">
            <p>
              <a
                href="#"
                className="text-primary"
                onClick={this.ForgotPasswordHandler}
              >
                Forgot Password
              </a>
            </p>
          </div>
          <div className="col-md-12 p-0 mt-3">
            <ErrorMessage
              {...this.props}
              error={this.props.loginStatus.message}
              className="col-md-12"
            />
          </div>
          <Version className="" />
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    loginStatus: state.auth.loginStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogin: (username, password) =>
      dispatch(actions.login(username, password)),
    onForgotPasswordStatus: (status) =>
      dispatch(actions.forgotPasswordStatus(status)),
    //, onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/"))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(axiosInterceptors(Login, service));
