import * as actionTypes from "../store/actions/actionTypes";

const listActions = prefix => {
  const start = isExport => {
    return {
      type: actionTypes[`${isExport ? prefix + "_EXPORT" : prefix}_START`]
    };
  };

  const success = (list, filterOptions, isExport) => {
    const Total = Array.isArray(list) ? list.pop().Total : 0;
    return {
      type: actionTypes[`${isExport ? prefix + "_EXPORT" : prefix}_SUCCESS`],
      list: list,
      filterOptions: filterOptions,
      total: Total
    };
  };

  const fail = (error, filterOptions) => {
    return {
      type: actionTypes[`${prefix}_FAIL`],
      error: error,
      filterOptions: filterOptions
    };
  };

  return {
    start,
    success,
    fail
  };
};

export default listActions;
