import React from "react";
import classNames from "classnames";
import { isNotNull } from "../helpers/utility";
const Textarea = (props) => {
  const renderTextarea = () => {
    return (
      <textarea
        rows={props.row}
        cols={props.cols}
        disabled={
          isNotNull(props.disabled) ? (props.disabled ? "disabled" : "") : ""
        }
        type={props.type}
        id={props.id}
        value={props.value}
        className={classNames({
          "placeholder-shown": true,
          "form-control": true,
          "Is-Invalid": !props.valid && props.isSubmited,
        })}
        placeholder={props.placeholder}
        onChange={(event) =>
          props.onChange !== undefined
            ? props.onChange(props.id, event.target.value)
            : null
        }
        onBlur={(e) => props.onBlur && props.onBlur(e)}
      />
    );
  };
  return (
    <div className="form-group">
      {props.label !== null &&
      props.label !== undefined &&
      props.label !== "" ? (
        <label className="">{props.label}</label>
      ) : null}
      {isNotNull(props.append) || isNotNull(props.prepend) ? (
        <div className="input-group">
          {isNotNull(props.prepend) && (
            <div className="input-group-prepend">
              <span className="input-group-text">{props.prepend}</span>
            </div>
          )}
          {renderTextarea()}
          {isNotNull(props.append) && (
            <div className="input-group-append">
              <span className="input-group-text">{props.append}</span>
            </div>
          )}
        </div>
      ) : (
        renderTextarea()
      )}
    </div>
  );
};

export default Textarea;
