import React from "react";
import CustomCheckbox from "../../components/Chcekbox/CustomCheckbox";

const celCheckboxSelect = (props) => {
  const { change, id, checked, disabled, field } = props;

  let ckeck = () => {
    return (
      <CustomCheckbox
        id={"checkbox"+id}
        value={id}
        className="form-check-input"
        checked={checked}
        disabled={disabled}
        onChange={(event) => change(event, "checkboxClick", id, field)}
      />
    );
  };

  return (
    <td>
      <div className="form-check">{ckeck()}</div>
    </td>
  );
};

export default celCheckboxSelect;
