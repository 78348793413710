import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { isNotNull } from "../helpers/utility";
const Alert = props => {
  const ClassName = () => {
    return classNames(
      props.className,
      { "alert-success": props.status === "success" },
      { "alert-danger": props.status === "danger" },
      { "alert-warning": props.status === "warning" },
      { "alert-info": props.status === "info" },
      { "d-none": props.status === "" },
      "alert"
    );
  };

  return (
    <>
      {isNotNull(props.message) && isNotNull(props.status) ? (
        <div className={ClassName()}>
          <strong>
            {_.capitalize(props.status === "danger" ? "Error" : props.status)}!
            &nbsp;
          </strong>
          {props.message}
        </div>
      ) : null}
    </>
  );
};

export default Alert;
