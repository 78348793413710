import service from "../../services/userManagement.service";
import * as actionTypes from "./actionTypes";

export const listPermissionStart = () => {
  console.log("LIST_PERMISSIONS_START");
  return {
    type: actionTypes.LIST_PERMISSIONS_START
  };
};

export const resetForm = () => {
  return {
    type: actionTypes.LIST_PERMISSIONS_RESETFORM
  };
};

export const updateRolesPermissionsSuccess = updatedPermissionList => {
  console.log(
    "UPDATE_ROLES_PPERMISSIONS_SUCCESS1 " +
      JSON.stringify(updatedPermissionList, null, 2)
  );
  return {
    type: actionTypes.UPDATE_ROLES_PPERMISSIONS_SUCCESS,
    addedRole: updatedPermissionList
  };
};

export const addRolesPermissionsSuccess = addedRole => {
  return {
    type: actionTypes.ADD_ROLES_PPERMISSIONS_SUCCESS,
    addedRole: addedRole
  };
};

export const listPermissionSuccess = (permissionList, filterOptions) => {
  // console.log(
  //   "listPermissionSuccess reduucer2" + JSON.stringify(permissionList, null, 2)
  // );
  return {
    type: actionTypes.LIST_PERMISSIONS_SUCCESS,
    permissionList: permissionList,
    filterOptions: filterOptions
    // total: Total
  };
};

export const listPermissionFail = (error, filterOptions) => {
  return {
    type: actionTypes.LIST_PERMISSIONS_FAIL,
    error: error,
    filterOptions: filterOptions
  };
};

export const searchboxChange = keyword => {
  return {
    type: actionTypes.LIST_PERMISSIONS_SEARCHBOX_CHANGE,
    keyword: keyword
  };
};

export const selectPermission = Permission => {
  console.log(
    "-----------------------------Select Permission Action------------ "
  );
  return {
    type: actionTypes.LIST_PERMISSIONS_SELECT,
    selectedPermission: Permission
  };
};

export const selectePermissionCategory = Permission => {
  console.log(
    "-----------------------------Select Permission Action------------ "
  );
  return {
    type: actionTypes.LIST_PERMISSIONS_Category_SELECT,
    selectedPermissionCategory: Permission
  };
};
export const listPermissions = filterOptions => {
  // console.log(
  //   "Service call Permissions 111 filterOptions---- " +
  //     JSON.stringify(filterOptions)
  // );
  console.log("listPermissions filterOptions " + filterOptions);
  var url = filterOptions ? `/permission?r_id=${filterOptions}` : "/permission";
  return dispatch => {
    dispatch(listPermissionStart());
    service
      .get(url)
      .then(res => {
        console.log(
          "listPermissionSuccess reduucer1" + JSON.stringify(res.data, null, 2)
        );
        let data = filterOptions
          ? res.data
          : res.data.map(item => {
              return { ...item, isSelected: "false" };
            });
        dispatch(listPermissionSuccess(data, filterOptions));
      })
      .catch(err => {
        console.log("Service Error ---------------------------------------");
        console.log(err);
        dispatch(listPermissionFail(err, filterOptions));
      });
  };
};

export const updatePermissions = (roleId, permissions) => {
  console.log("onUpdatePermissions " + roleId + JSON.stringify(permissions));
  console.dir(roleId);
  var url = `/role/${roleId}/permissions`;
  return dispatch => {
    dispatch(listPermissionStart());
    service
      .post(url, { p_ids: permissions })
      .then(res => {
        console.log(
          "listPermissionSuccess reduucer1" +
            JSON.stringify(permissions, null, 2)
        );
        dispatch(updateRolesPermissionsSuccess(res.data));
      })
      .catch(err => {
        console.log("Service Error ---------------------------------------");
        console.log(err);
        dispatch(listPermissionFail(err, null));
      });
  };
};

export const insertRole = (title, permissions) => {
  console.log("onUpdatePermissions " + title + JSON.stringify(permissions));
  console.dir(title);
  var url = `/role`;
  return dispatch => {
    dispatch(listPermissionStart());
    service
      .post(url, { r_title: title, r_mfa: "false", permissions: permissions })
      .then(res => {
        dispatch(addRolesPermissionsSuccess(res.data));
      })
      .catch(err => {
        console.log("Service Error ---------------------------------------");
        console.log(err);
        dispatch(listPermissionFail(err, null));
      });
  };
};
